import {
  Home,
  Shop,
  ShopDetail,
  Cart,
  About,
  ObjectShop,
  TextPage,
  CallBack,
  Sound,
} from '../containers';

export const routes = [
  {
    title: 'Home',
    path: '*',
    component: <Home />,
    protected: false,
  },
  {
    title: 'Shop',
    path: '/shop',
    component: <Shop />,
    protected: false,
  },
  {
    title: 'Shop',
    path: '/shop/:id',
    component: <ShopDetail />,
    protected: false,
  },
  {
    title: 'Cart',
    path: '/cart',
    component: <Cart />,
    protected: false,
  },
  {
    title: 'About',
    path: '/about',
    component: <About />,
    protected: false,
  },
  {
    title: 'ObjectShop',
    path: '/nesneler',
    component: <ObjectShop />,
    protected: false,
  },
  {
    title: 'Gizlilik Politikası',
    path: '/gizlilik-politikasi',
    component: <TextPage />,
    protected: false,
  },
  {
    title: 'Mesafeli Satış Sözleşmesi',
    path: '/mesafeli-satis-sozlesmesi',
    component: <TextPage />,
    protected: false,
  },
  {
    title: 'İade Politikası',
    path: '/iade-politikasi',
    component: <TextPage />,
    protected: false,
  },
  {
    title: 'Success',
    path: '/payment/success',
    component: <CallBack />,
    protected: false,
  },
  {
    title: 'Error',
    path: '/payment/error',
    component: <CallBack />,
    protected: false,
  },
  {
    title: 'Sound',
    path: '/sesler',
    component: <Sound />,
    protected: false,
  },
];
