import style from './header.module.scss';

import Logotype from '../../assets/img/logo/logotype.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { changeLanguage } from '../../redux/actions';
import { LanguageData } from '../../utils/LanguageData';
import useWindowDimensions from '../../helpers/windowWidth';

const Header = ({ isCart, shop, about, noCart }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const currentLocation = location.pathname.split('/')[1];
  const { width } = useWindowDimensions();
  const cartData = useSelector(state => state.cart);
  const currentLang = useSelector(state => state.settings.language);

  return (
    <header className={style.header}>
      <a className={style.logo} href="/">
        <img src={Logotype} alt="logo" />
      </a>

      <div className={style.buttonWrapper}>
        {shop || width > 938 ? (
          <a
            href="/shop"
            className={`${style.shop} ${
              currentLocation === 'shop' && style.active
            }`}
          >
            {LanguageData[currentLang].header.shop[1]}
            <br /> {LanguageData[currentLang].header.shop[2]}
            <div className={style.circle} />
            <div className={style.circle} />
            <div className={style.circle} />
            <div className={style.circle} />
          </a>
        ) : null}
        {about || width > 938 ? (
          <a
            href="/about"
            className={`${style.about} ${
              currentLocation === 'about' && style.active
            }`}
          >
            {LanguageData[currentLang].header.about[1]}
            <br /> {LanguageData[currentLang].header.about[2]}
            <div className={style.circle} />
            <div className={style.circle} />
            <div className={style.circle} />
            <div className={style.circle} />
            <div className={style.circle} />
          </a>
        ) : null}
        {!noCart && (
          <button
            className={` ${currentLocation === 'cart' && style.active}`}
            onClick={() => navigate('/cart')}
          >
            <h5> {LanguageData[currentLang].header.cart}</h5>
            {cartData.cart && cartData.cart.length ? (
              <h6>{cartData.cart.length}</h6>
            ) : null}
            <div className={style.circle} />
            <div className={style.circle} />
            <div className={style.circle} />
          </button>
        )}
      </div>
    </header>
  );
};

export default Header;
