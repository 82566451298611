import { ADD_TO_CART, REMOVE_CART, CHANGE_QUANTITY, CLEAR_CART } from '../type';

const initialState = {
  cart: [],
};

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_CART:
      const isItem = state.cart.filter(i => i.id === action.payload.id)[0];
      return {
        ...state,
        cart: state.cart.length
          ? state.cart.filter(i => i.id === action.payload.id).length > 0
            ? [
                ...state.cart.filter(i => i.id !== action.payload.id),
                {
                  ...isItem,
                  quantity:
                    isItem.quantity !== 3
                      ? isItem.quantity + 1
                      : isItem.quantity,
                },
              ].sort((a, b) => a.sort - b.sort)
            : [...state.cart, { ...action.payload, sort: state.cart.length }]
          : [...state.cart, { ...action.payload, sort: 0 }],
      };

    case CHANGE_QUANTITY:
      const currentItem = state.cart.filter(i => i.id === action.payload.id)[0];

      return {
        ...state,
        cart: [
          ...state.cart.filter(i => i.id !== action.payload.id),
          {
            ...currentItem,
            quantity:
              action.payload.type === 'down' && currentItem.quantity !== 1
                ? currentItem.quantity - 1
                : action.payload.type === 'up' && currentItem.quantity !== 3
                ? currentItem.quantity + 1
                : currentItem.quantity,
          },
        ].sort((a, b) => a.sort - b.sort),
      };
    case REMOVE_CART:
      return {
        ...state,
        cart: state.cart.filter(i => i.id !== action.payload),
      };
    case CLEAR_CART:
      return {
        ...state,
        cart: [],
      };
    default:
      return state;
  }
}
