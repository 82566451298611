import style from './objectshop.module.scss';
import Istakoz from '../../assets/img/home/istakoz.png';
import SandCastle from '../../assets/img/shop/sand-castle.svg';
import ObjectSeperator from '../../assets/img/shop/object-seperator.svg';
import { BASE_URL, ApiRoutes } from '../../utils/ApiRoutes';
import { useEffect } from 'react';
import { useState } from 'react';
import { slugify } from '../../helpers/Slugify';
import { Header, Footer } from '../../components';
import axios from 'axios';

import Canta from '../../assets/img/shop/object/canta.png';
import Tshirt from '../../assets/img/shop/object/tshirt.png';

import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../redux/actions';
import { LanguageData } from '../../utils/LanguageData';
import Arrow from '../../assets/img/shop/next-item-white.svg';

const ObjectShop = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [selectedSize, setSelectedSize] = useState('');

  const currentLang = useSelector(state => state.settings.language);

  useEffect(() => {
    axios
      .get(`${BASE_URL}${ApiRoutes.website.getProductList}`)
      .then(res => {
        console.log(res.data);
        setData(res.data.filter(i => i.productType === 'Object').reverse());
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className={style.objectShop}>
        <Header shop />
        <div className={style.content}>
          <div className={style.left}>
            <div className={style.etiket}>
              DANTE & ISTAKOZ {LanguageData[currentLang].about.right.object}
            </div>
            <img
              src={SandCastle}
              alt="sand-castle"
              className={style.sandCastle}
            />
          </div>
          <div className={style.center}>
            <div className={style.itemWrapper}>
              {data &&
                data.map((item, index) => {
                  return (
                    <div className={style.item}>
                      <img src={item.files[0].fileUrl} alt={item.title} />
                      <div className={style.circle}>
                        <h3>{item.title[currentLang]}</h3>
                        <h5>{item.price} TL</h5>
                        {index === 0 && (
                          <label className={style.sizeSelect}>
                            <span>{selectedSize ? selectedSize : 'S-M-L'}</span>
                            <select
                              onChange={e => {
                                setSelectedSize(e.target.value);
                              }}
                            >
                              <option value={'S'}>S</option>
                              <option value={'M'}>M</option>
                              <option value={'L'}>L</option>
                            </select>
                            <img src={Arrow} className={style.arrow} />
                          </label>
                        )}
                        <button
                          onClick={() => {
                            if (index === 0 && selectedSize) {
                              dispatch(
                                addToCart({
                                  id: item._id,
                                  title: item.title.tr,
                                  price: item.price,
                                  coverImage: item.files[0].fileUrl,
                                  quantity: 1,
                                  size: selectedSize,
                                })
                              );
                            } else if (index > 0) {
                              dispatch(
                                addToCart({
                                  id: item._id,
                                  title: item.title.tr,
                                  price: item.price,
                                  coverImage: item.files[0].fileUrl,
                                  quantity: 1,
                                })
                              );
                            }
                          }}
                        >
                          {LanguageData[currentLang].shopDetail.addToCart[1]}{' '}
                          {LanguageData[currentLang].shopDetail.addToCart[2]}
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>
            <img
              src={ObjectSeperator}
              alt="seperator"
              className={style.seperator}
            />
          </div>
          <div className={style.right}>
            <img src={Istakoz} alt="istakoz" className={style.istakoz} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ObjectShop;
