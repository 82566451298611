import { ADD_TO_CART, CHANGE_QUANTITY, REMOVE_CART, CLEAR_CART } from '../type';

export const addToCart = payload => {
  return {
    type: ADD_TO_CART,
    payload: payload,
  };
};

export const removeCart = payload => {
  return {
    type: REMOVE_CART,
    payload: payload,
  };
};

export const changeQuantity = payload => {
  return {
    type: CHANGE_QUANTITY,
    payload: payload,
  };
};

export const clearCart = payload => {
  return {
    type: CLEAR_CART,
    payload: payload,
  };
};
