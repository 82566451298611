export const BASE_URL = 'https://api.danteveistakoz.com/';
// export const BASE_URL = 'http://localhost:5050/';

export const ApiRoutes = {
  payment: {
    create: 'payment/createPaymentForm',
  },
  website: {
    getProductList: 'website/getProductList',
    getProduct: 'website/getProduct',
  },
};
