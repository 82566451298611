import style from "./sound.module.scss";
import Istakoz from "../../assets/img/home/istakoz.png";
import SandCastle from "../../assets/img/shop/sand-castle.svg";
import ObjectSeperator from "../../assets/img/shop/object-seperator.svg";
import { BASE_URL, ApiRoutes } from "../../utils/ApiRoutes";
import { useEffect } from "react";
import { useState } from "react";
import { slugify } from "../../helpers/Slugify";
import { Header, Footer } from "../../components";
import axios from "axios";

import Canta from "../../assets/img/shop/object/canta.png";
import Tshirt from "../../assets/img/shop/object/tshirt.png";

import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../redux/actions";
import { LanguageData } from "../../utils/LanguageData";
import Arrow from "../../assets/img/shop/next-item-white.svg";

const Sound = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [selectedSize, setSelectedSize] = useState("");

  const currentLang = useSelector((state) => state.settings.language);

  useEffect(() => {
    axios
      .get(`${BASE_URL}${ApiRoutes.website.getProductList}`)
      .then((res) => {
        console.log(res.data);
        setData(res.data.filter((i) => i.productType === "Object").reverse());
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className={style.objectShop}>
        <Header shop />
        <div className={style.content}>
          <div className={style.left}>
            <div className={style.etiket}>
              {LanguageData[currentLang].about.right.soundTitle}
            </div>
            <img
              src={SandCastle}
              alt="sand-castle"
              className={style.sandCastle}
            />
          </div>
          <div className={style.center}>
            <div className={style.itemWrapper}>
              <iframe
                frameborder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1700822967&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
              ></iframe>
              <div className={style.text}>
                <p>{LanguageData[currentLang].sound.text1}</p>
                <p>{LanguageData[currentLang].sound.text2}</p>
                <p>{LanguageData[currentLang].sound.text3}</p>
              </div>
              <iframe
                frameborder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1856485755&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
              ></iframe>

              <div className={style.text}>
                <p>{LanguageData[currentLang].sound2.text1}</p>
                <p>{LanguageData[currentLang].sound2.text2}</p>
                <p>{LanguageData[currentLang].sound2.text3}</p>
              </div>
              {/* <iframe
                frameborder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1700822967&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe> */}
            </div>
            <img
              src={ObjectSeperator}
              alt="seperator"
              className={style.seperator}
            />
          </div>
          <div className={style.right}>
            <img src={Istakoz} alt="istakoz" className={style.istakoz} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Sound;
