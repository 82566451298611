import ExodusCover from '../assets/img/shop/exodus/exodus-cover.png';
import Exodus1 from '../assets/img/shop/exodus/exodus-1.JPG';
import Exodus2 from '../assets/img/shop/exodus/exodus-2.JPG';
import Exodus3 from '../assets/img/shop/exodus/exodus-3.JPG';
import Exodus4 from '../assets/img/shop/exodus/exodus-4.JPG';

import BekledimCover from '../assets/img/shop/bekledim/bekledim-cover.png';
import Bekledim1 from '../assets/img/shop/bekledim/bekledim-1.jpg';
import Bekledim2 from '../assets/img/shop/bekledim/bekledim-2.jpg';
import Bekledim3 from '../assets/img/shop/bekledim/bekledim-3.jpg';

import ComicCover from '../assets/img/shop/comic/comic-cover.png';
import Comic1 from '../assets/img/shop/comic/comic-1.jpg';
import Comic2 from '../assets/img/shop/comic/comic-2.jpg';
import Comic3 from '../assets/img/shop/comic/comic-3.jpg';

import HeavyCover from '../assets/img/shop/heavy/heavy-cover.png';
import Heavy1 from '../assets/img/shop/heavy/heavy-1.jpg';
import Heavy2 from '../assets/img/shop/heavy/heavy-2.jpg';
import Heavy3 from '../assets/img/shop/heavy/heavy-3.jpg';

import LoveCover from '../assets/img/shop/love/love-cover.png';
import Love1 from '../assets/img/shop/love/love-1.jpg';
import Love2 from '../assets/img/shop/love/love-2.jpg';
import Love3 from '../assets/img/shop/love/love-3.jpg';

import SagkalanCover from '../assets/img/shop/sagkalan/sagkalan-cover.png';
import Sagkalan1 from '../assets/img/shop/sagkalan/sagkalan-1.jpeg';
import Sagkalan2 from '../assets/img/shop/sagkalan/sagkalan-2.jpeg';

export const ShopData = [
  {
    title: '3 AM EXODUS',
    author: 'DELFIN LEV',
    coverImage: ExodusCover,
    path: '3-am-exodus',
    shopImages: [Exodus1, Exodus2, Exodus3, Exodus4],
    desc: 'Delfin Lev’in ilk kitabı 3 AM Exodus, 12 kişinin isimleriyle oluşturulmuş şiirlerden oluşan İngilizce bir yayın. Her şiir, bahsedilen 12 kişinin isimlerinin nümerik karşılıkları olan kelimelerle yazılı ve böylece şiirler öykülerini Gematria ile anlatıyor.',
    edition: '100 adet',
    size: '13x17 cm',
    pageNumber: '64 sayfa',
    material: [
      'İç sayfaların kağıdı 120 gr Munken Pure',
      'Kapak 300 gr Munken Pure, mor ve yeşil yaldız',
      'İplik dikiş, Amerikan cilt',
    ],
    design: 'E S Kibele Yarman',
    print: 'Printcenter',
    photo: 'Zeynep Fırat',
    year: '2021',
    price: '130',
  },
  {
    title: 'BEKLEDİM',
    author: 'YAĞMUR ÖZDEMİR',
    coverImage: BekledimCover,
    path: 'bekledim',
    shopImages: [Bekledim1, Bekledim2, Bekledim3],
    desc: 'Sakince Godot’yu bekleyen bir anti-photobook photobook.',
    edition: '50 adet',
    size: '13x17 cm',
    pageNumber: '60 sayfa',
    material: [
      'İç sayfaların kağıdı 120 gr Munken Pure',
      'Kapak 300 gr Munken Pure, gümüş yaldız',
      'İplik dikiş, Amerikan cilt',
    ],
    design: 'E S Kibele Yarman',
    print: 'Printcenter',
    photo: 'Zeynep Fırat',
    year: '2021',
    price: '140',
  },
  {
    title: 'HEAVY-HANDED',
    author: 'NAJEEBAH AL-GHADBAN',
    coverImage: HeavyCover,
    path: 'heavy-handed',
    shopImages: [Heavy1, Heavy2, Heavy3],
    desc: `Beklemek üzerine bir kartpostal kitap.

    Hiç gelmezsen, hiçbir zaman veda etmen gerekmez. Ya da Orpheus’un hikayesini baştan anlatmak. Eğer görmek için geriye bakman gerekiyorsa, 
    orada değildir.`,
    edition: '100 adet',
    size: '10,4x14,7 cm',
    pageNumber: '80 sayfa',
    material: [
      'İç sayfaların kağıdı 200 gr Munken Pure, perfore',
      'Kapak kağıdı 360 gr Fedrigoni Nettuno',
      'İç sayfalar bez ile ciltlenmiş, dış kapak cilde geçme',
    ],
    print: 'Printcenter',
    photo: 'Zeynep Fırat',
    year: '2021',
    price: '220',
  },
  {
    title: 'SAĞ KALAN',
    author: 'MUTLU PARKAN',
    coverImage: SagkalanCover,
    path: 'sag-kalan',
    shopImages: [Sagkalan1, Sagkalan2],
    desc: `Mutlu Parkan’ın aramızdan ayrılmadan önce yayımlanmasını önemsediği, lisedeki mecburi tarih derslerinde pek de değinilmeyen Türkiye’nin yakın tarihini merak eden GEN Z’ler için yumuşak bir giriş, önceki nesiller için ise hızla okunabilen bir hatırlama.`,
    edition: '750 adet',
    editor: 'Oğuz Tecimen',
    design: 'E S Kibele Yarman',
    size: '13x17 cm',
    pageNumber: '120 sayfa',
    material: [
      'İç sayfalar 80 gr EnsoLux',
      'Sert kapağa Ceffe cilt bezi kaplama',
      'İç sayfalar bez ile ciltlenmiş, dış kapak cilde geçme',
    ],
    print: 'Printcenter',
    year: '2021',

    price: '120',
  },
  {
    title: 'A LOVE MYSTERY',
    author: 'HATİCE UTKAN ÖZDEN',
    coverImage: LoveCover,
    path: 'a-love-mystery',
    shopImages: [Love1, Love2, Love3],
    desc: `40 sanat işine eşlik etmek üzere yazar tarafından seçilmiş 40 el yazısıyla yazılmış şiir.`,
    edition: '50 adet',
    design: 'E S Kibele Yarman',
    size: '13x17 cm',
    pageNumber: '84 sayfa',
    material: [
      'İç sayfaların kağıdı 90 gr Nautilus',
      'Kapağın kağıdı 300 gr Nautilus',
      'İplik dikiş',
    ],
    print: 'Printcenter',
    year: '2021',
    price: '300',
  },
  {
    title: 'GİZEM VURAL COMIC COLLECTION BOOK',
    author: 'GİZEM VURAL',
    coverImage: ComicCover,
    shopImages: [Comic1, Comic2, Comic3],
    path: 'gizem-vural-comic-collection-book',
    desc: `Gizem Vural’ın 2017—2021 yılları arasında ürettiği çizgi roman paneli strüktürünün sınırlarını yeniden tanımlayan illüstrasyonlarının bir koleksiyonundan oluşan kitap. İllüstrasyonlar kelimeleri kullanmadan, tek sayfa üzerinden işleniyor ve soyut temalara ek olarak zaman, büyüme, doğa, uzay ve duyguları konu alıyor.`,
    edition: '200 adet',
    design: 'E S Kibele Yarman',
    text: `R J Casey`,
    size: '14x20 cm',
    pageNumber: '144 sayfa',
    material: [
      'İç sayfalar 115 gr Tatami White kağıt',
      'Açık sırt, iplik dikiş',
      'Bir illüstrasyon kitabın içinden baskı olarak çıkıyor',
    ],
    print: 'Printcenter',
    year: '2021',
    price: '630',
    paragraphLow: true,
  },
];
