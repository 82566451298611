import style from './cart.module.scss';
import ShellIcon from '../../assets/img/shop/shell-icon.svg';
import CartSeperator from '../../assets/img/shop/cart-seperator-icon.svg';
import { Header, Footer } from '../../components';
import CartIcon from '../../assets/img/shop/cart-icon.svg';
import { useSelector, useDispatch } from 'react-redux';
import { removeCart, changeQuantity } from '../../redux/actions';
import CartFormSeperator from '../../assets/img/shop/cart-form-seperator.svg';
import { CityData } from '../../data/CityData';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Field } from 'formik';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { ApiRoutes, BASE_URL } from '../../utils/ApiRoutes';
import NextItem from '../../assets/img/shop/next-item.svg';
import { LanguageData } from '../../utils/LanguageData';
import useWindowDimensions from '../../helpers/windowWidth';
import EmptyCartMobile from '../../assets/img/shop/empty-cart-mobile.svg';

const Cart = () => {
  const cartData = useSelector(state => state.cart).cart;
  const dispatch = useDispatch();
  const currentLang = useSelector(state => state.settings.language);
  const [error, setError] = useState([]);
  const { width } = useWindowDimensions();
  const [isBilling, setBillign] = useState(false);

  const phoneRegExp = new RegExp(
    /^(5)([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/
  );
  const tcRegExp = new RegExp(/^[1-9]{1}[0-9]{9}[02468]{1}$/);

  const PaymentSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Kısa isim.')
      .required(currentLang === 'en' ? 'Please fill in' : 'Zorunlu alan'),
    city: Yup.string().required(
      currentLang === 'en' ? 'Please Select' : 'Zorunlu alan'
    ),
    address: Yup.string()
      .min(8, 'Kısa adres.')
      .required(currentLang === 'en' ? 'Please fill in' : 'Zorunlu alan'),
    phone: Yup.string().required(
      currentLang === 'en' ? 'Please fill in' : 'Zorunlu alan'
    ),
    tcNo: Yup.string().required(
      currentLang === 'en'
        ? 'Please fill in'
        : currentLang === 'en'
        ? 'Please fill in'
        : 'Zorunlu alan'
    ),
    email: Yup.string()
      .required(currentLang === 'en' ? 'Please fill in' : 'Zorunlu alan')
      .email('Lütfen geçerli bir email giriniz'),
  });

  // name: '',
  // tcNo: '',
  // phone: '',
  // email: '',
  // city: '',
  // address: '',

  const formRef = useRef();
  const formRef2 = useRef();

  useEffect(() => {
    if (formRef.current) {
      console.log(formRef.current);
    }
  }, [formRef.current]);

  const handleSubmit = () => {
    if (formRef.current && formRef2.current) {
      formRef.current.handleSubmit();
      // formRef2.current.handleSubmit();
      if (
        formRef.current.values.name &&
        formRef.current.values.phone &&
        formRef.current.values.email &&
        formRef.current.values.tcNo &&
        formRef.current.values.city &&
        formRef.current.values.address
      ) {
        if (isBilling) {
          createPayment(formRef.current.values, formRef2.current.values);
        } else {
          createPayment(formRef.current.values, '');
        }
      }
    }
  };

  const createPayment = (shipment, billing) => {
    axios
      .post(`${BASE_URL}${ApiRoutes.payment.create}`, {
        shipment: {
          name: shipment.name,
          phone: shipment.phone,
          email: shipment.email,
          tcNo: shipment.tcNo,
          address: shipment.address,
          city: shipment.city,
        },
        billing: {
          name: billing && billing.name ? billing.name : shipment.name,
          phone: billing && billing.phone ? billing.phone : shipment.phone,
          email: billing && billing.email ? billing.email : shipment.email,
          tcNo: billing && billing.tcNo ? billing.tcNo : shipment.tcNo,
          address:
            billing && billing.address ? billing.address : shipment.address,
          city: billing && billing.city ? billing.city : shipment.city,
        },
        basket: cartData,
      })
      .then(res => {
        console.log(res.data);
        window.open(res.data, '_self');
      });
  };

  const sizeData = [
    {
      size: 'S',
      title: 'SMALL',
    },
    {
      size: 'M',
      title: 'MEDIUM',
    },
    {
      size: 'L',
      title: 'LARGE',
    },
  ];

  return (
    <>
      <div className={style.cart}>
        <Header shop />
        <img className={style.cartIcon} src={CartIcon} alt="cart" />
        <div className={style.content}>
          <img className={style.shell} src={ShellIcon} alt="shell" />
          <div className={style.cartSide}>
            <div className={style.itemWrapper}>
              {cartData &&
                cartData.map(item => {
                  return (
                    <div className={style.item}>
                      {width < 938 ? (
                        <div className={style.leftItem}>
                          <img
                            className={style.mainImage}
                            src={item.coverImage}
                            alt={item.title}
                          />
                          <div className={style.countwrapper}>
                            <button
                              onClick={() => {
                                if (item.quantity < 4) {
                                  setError(error.filter(i => i !== item.id));
                                }

                                if (item.quantity === 1) {
                                  dispatch(removeCart(item.id));
                                } else {
                                  dispatch(
                                    changeQuantity({
                                      id: item.id,
                                      type: 'down',
                                    })
                                  );
                                }
                              }}
                              className={style.downButton}
                            >
                              <img src={NextItem} alt="down" />
                            </button>

                            <div className={style.count}>
                              {currentLang === 'en' ? (
                                <>
                                  {LanguageData[currentLang].form.piece}{' '}
                                  {item.quantity}
                                </>
                              ) : (
                                <>
                                  {item.quantity}{' '}
                                  {LanguageData[currentLang].form.piece}
                                </>
                              )}
                            </div>

                            <button
                              onClick={() => {
                                if (item.quantity === 3) {
                                  setError([...error, item.id]);
                                }

                                dispatch(
                                  changeQuantity({
                                    id: item.id,
                                    type: 'up',
                                  })
                                );
                              }}
                              className={style.upButton}
                            >
                              <img src={NextItem} alt="up" />
                            </button>
                            {/* <button
                              className={style.deleteButton}
                              onClick={() => {
                               
                              }}
                            >
                              Kaldır
                            </button> */}
                          </div>
                        </div>
                      ) : (
                        <img
                          className={style.mainImage}
                          src={item.coverImage}
                          alt={item.title}
                        />
                      )}
                      {width < 938 && (
                        <div className={style.mobileInfo}>
                          <h3>{item.title}</h3>
                          {item.size && (
                            <h4>
                              PAMUKLU,
                              <br />
                              {
                                sizeData.filter(i => i.size === item.size)[0]
                                  .title
                              }{' '}
                              {LanguageData[currentLang].form.sizeData}
                            </h4>
                          )}
                          {item.author ? <h4>{item.author}</h4> : null}
                          {currentLang === 'en' ? (
                            <h6>
                              <span>{item.price} TL</span>
                              {LanguageData[currentLang].shopDetail.cargo}
                            </h6>
                          ) : (
                            <h6>
                              {' '}
                              {LanguageData[currentLang].shopDetail.cargo}{' '}
                              <span>{item.price} TL</span>
                            </h6>
                          )}
                          {error.includes(item.id) ? (
                            <h6 className={style.maxItem}>
                              En fazla 3 adet ekleyebilirsiniz. Daha fazla almak
                              için: danteveistakoz@gmail.com'a mail
                              atabilirsiniz.
                            </h6>
                          ) : null}
                        </div>
                      )}

                      <div className={style.info}>
                        <h3>{item.title}</h3>
                        {item.size && (
                          <h4>
                            PAMUKLU,
                            <br />
                            {
                              sizeData.filter(i => i.size === item.size)[0]
                                .title
                            }{' '}
                            {LanguageData[currentLang].form.sizeData}
                          </h4>
                        )}
                        {item.author ? <h4>{item.author}</h4> : null}
                        {/* {item.edition ? (
                          <h5>
                            <span>Edisyon:</span>
                            {item.edition}
                          </h5>
                        ) : null} */}
                        {currentLang === 'en' ? (
                          <h6>
                            {item.price} TL
                            {LanguageData[currentLang].shopDetail.cargo}
                          </h6>
                        ) : (
                          <h6>
                            {' '}
                            {LanguageData[currentLang].shopDetail.cargo}{' '}
                            {item.price} TL
                          </h6>
                        )}
                      </div>
                      {width > 938 && (
                        <div className={style.countwrapper}>
                          {error.includes(item.id) ? (
                            <h6 className={style.maxItemDesktop}>
                              En fazla 3 adet ekleyebilirsiniz. Daha fazla almak
                              için: danteveistakoz@gmail.com'a mail
                              atabilirsiniz.
                            </h6>
                          ) : null}
                          <button
                            onClick={() => {
                              if (item.quantity < 4) {
                                setError(error.filter(i => i !== item.id));
                              }
                              if (item.quantity === 1) {
                                dispatch(removeCart(item.id));
                              } else {
                                dispatch(
                                  changeQuantity({
                                    id: item.id,
                                    type: 'down',
                                  })
                                );
                              }
                            }}
                            className={style.downButton}
                          >
                            <img src={NextItem} alt="down" />
                          </button>

                          <div className={style.count}>
                            {currentLang === 'en' ? (
                              <>
                                {LanguageData[currentLang].form.piece}{' '}
                                {item.quantity}
                              </>
                            ) : (
                              <>
                                {item.quantity}{' '}
                                {LanguageData[currentLang].form.piece}
                              </>
                            )}
                          </div>

                          <button
                            className={style.upButton}
                            onClick={() => {
                              if (item.quantity === 3) {
                                setError([...error, item.id]);
                              }

                              dispatch(
                                changeQuantity({
                                  id: item.id,
                                  type: 'up',
                                })
                              );
                            }}
                          >
                            <img src={NextItem} alt="up" />
                          </button>
                          {/* <button
                            className={style.deleteButton}
                            onClick={() => {
                              dispatch(removeCart(item.id));
                            }}
                          >
                            Kaldır
                          </button> */}
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
            {cartData && !cartData.length ? (
              <>
                <div className={style.emptyCartWrapper}>
                  <h5 className={style.emptyCart}>
                    HENÜZ SEPETİNİZ BOŞ GİBİ GÖRÜNÜYOR.
                  </h5>
                  {width < 938 && (
                    <img
                      src={EmptyCartMobile}
                      className={style.emptyCartImage}
                    />
                  )}
                </div>
              </>
            ) : null}
            <div className={style.bottom}>
              {width > 938 && (
                <img
                  src={CartSeperator}
                  alt="seperator"
                  className={style.seperator}
                />
              )}
              {cartData && cartData.length ? (
                <div className={style.payment}>
                  <div className={style.column}>
                    <h2>{LanguageData[currentLang].form.shippingTitle}</h2>

                    <Formik
                      initialValues={{
                        name: '',
                        tcNo: '',
                        phone: '',
                        email: '',
                        city: '',
                        address: '',
                      }}
                      validationSchema={PaymentSchema}
                      onSubmit={values => {
                        if (
                          values.name &&
                          values.phone &&
                          values.email &&
                          values.tcNo &&
                          values.city &&
                          values.address
                        ) {
                          if (isBilling) {
                            createPayment(values, formRef2.current.values);
                          } else {
                            createPayment(values, '');
                          }
                        }
                      }}
                      innerRef={formRef}

                      // innerRef={formikRef}
                    >
                      {({ errors, touched, values, handleSubmit }) => (
                        <Form id="payment">
                          <label className={values.name && style.fill}>
                            <h5> {LanguageData[currentLang].form.name}</h5>
                            <Field
                              type={'text'}
                              id="name"
                              name="name"
                              value={values.name}
                            />
                            {errors.name && touched.name ? (
                              <span>{errors.name}</span>
                            ) : null}
                          </label>
                          <label className={values.tcNo && style.fill}>
                            <h5>{LanguageData[currentLang].form.tcNo}</h5>
                            <Field
                              type={'text'}
                              id="tcNo"
                              name="tcNo"
                              value={values.tcNo}
                            />
                            {errors.tcNo && touched.tcNo ? (
                              <span>{errors.tcNo}</span>
                            ) : null}
                          </label>
                          <label className={values.phone && style.fill}>
                            <h5>{LanguageData[currentLang].form.phone}</h5>
                            <Field
                              type={'phone'}
                              id="phone"
                              name="phone"
                              value={values.phone}
                            />
                            {errors.phone && touched.phone ? (
                              <span>{errors.phone}</span>
                            ) : null}
                          </label>

                          <label className={values.email && style.fill}>
                            <h5>{LanguageData[currentLang].form.email}</h5>
                            <Field
                              type={'email'}
                              id="email"
                              name="email"
                              value={values.email}
                            />
                            {errors.email && touched.email ? (
                              <span>{errors.email}</span>
                            ) : null}
                          </label>
                          <label className={values.city && style.fill}>
                            <h5>{LanguageData[currentLang].form.city}</h5>
                            <Field
                              type={'city'}
                              id="city"
                              name="city"
                              value={values.city}
                              as="select"
                            >
                              <option selected value={''} disabled>
                                {LanguageData[currentLang].form.pleaseSelect}
                              </option>
                              {CityData.map(item => {
                                return (
                                  <option value={item.name}>{item.name}</option>
                                );
                              })}
                            </Field>
                            {errors.city && touched.city ? (
                              <span>{errors.city}</span>
                            ) : null}
                          </label>
                          <label className={values.address && style.fill}>
                            <h5>
                              {LanguageData[currentLang].form.shippingAddress}
                            </h5>
                            <Field
                              type={'address'}
                              id="address"
                              name="address"
                              value={values.address}
                              as="textarea"
                            />
                            {errors.address && touched.address ? (
                              <span>{errors.address}</span>
                            ) : null}
                          </label>
                        </Form>
                      )}
                    </Formik>
                  </div>

                  <img
                    src={CartFormSeperator}
                    className={style.cartFormSeperator}
                    alt="seperator"
                  />

                  {isBilling ? (
                    <div className={style.column}>
                      <h2>
                        {LanguageData[currentLang].form.billingTitle}
                        {/* <br />
                      [Teslimat Bilgilerinden Farklıysa] */}
                      </h2>
                      <Formik
                        initialValues={{
                          name: '',
                          tcNo: '',
                          phone: '',
                          email: '',
                          city: '',
                          address: '',
                        }}
                        // validationSchema={PaymentSchema}
                        onSubmit={values => {
                          console.log(values);
                        }}
                        innerRef={formRef2}
                        // innerRef={formikRef}
                      >
                        {({ errors, touched, values, handleSubmit }) => (
                          <Form>
                            <label className={values.name && style.fill}>
                              <h5> {LanguageData[currentLang].form.name}</h5>
                              <Field
                                type={'text'}
                                id="name"
                                name="name"
                                value={values.name}
                              />
                              {errors.name && touched.name ? (
                                <span>{errors.name}</span>
                              ) : null}
                            </label>
                            <label className={values.tcNo && style.fill}>
                              <h5>{LanguageData[currentLang].form.tcNo}</h5>
                              <Field
                                type={'text'}
                                id="tcNo"
                                name="tcNo"
                                value={values.tcNo}
                              />
                              {errors.tcNo && touched.tcNo ? (
                                <span>{errors.tcNo}</span>
                              ) : null}
                            </label>
                            <label className={values.phone && style.fill}>
                              <h5>{LanguageData[currentLang].form.phone}</h5>
                              <Field
                                type={'tel'}
                                id="phone"
                                name="phone"
                                value={values.phone}
                              />
                              {errors.phone && touched.phone ? (
                                <span>{errors.phone}</span>
                              ) : null}
                            </label>

                            <label className={values.email && style.fill}>
                              <h5>{LanguageData[currentLang].form.email}</h5>
                              <Field
                                type={'email'}
                                id="email"
                                name="email"
                                value={values.email}
                              />
                              {errors.email && touched.email ? (
                                <span>{errors.email}</span>
                              ) : null}
                            </label>
                            <label className={values.city && style.fill}>
                              <h5>{LanguageData[currentLang].form.city}</h5>
                              <Field
                                type={'city'}
                                id="city"
                                name="city"
                                value={values.city}
                                as="select"
                              >
                                <option selected value={''} disabled>
                                  {LanguageData[currentLang].form.pleaseSelect}
                                </option>
                                {CityData.map(item => {
                                  return (
                                    <option value={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </Field>
                              {errors.city && touched.city ? (
                                <span>{errors.city}</span>
                              ) : null}
                            </label>
                            <label className={values.address && style.fill}>
                              <h5>
                                {LanguageData[currentLang].form.billingAddress}
                              </h5>
                              <Field
                                type={'address'}
                                id="address"
                                name="address"
                                value={values.address}
                                as="textarea"
                              />
                              {errors.address && touched.address ? (
                                <span>{errors.address}</span>
                              ) : null}
                            </label>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  ) : (
                    <div className={style.column}>
                      <div className={style.billWrapper}>
                        <div className={style.billingRow}>
                          <h6>{LanguageData[currentLang].form.billingSame}</h6>
                          <div
                            className={`${style.billButton} ${
                              !isBilling && style.active
                            }`}
                          >
                            <img src={NextItem} alt="up" />
                            <div className={style.square} />
                          </div>
                        </div>
                        <div className={style.billingRow}>
                          <h6>
                            {LanguageData[currentLang].form.billingNotSame}
                          </h6>
                          <div
                            className={`${style.billButton} ${
                              isBilling && style.active
                            }`}
                            onClick={() => {
                              setBillign(true);
                            }}
                          >
                            <img src={NextItem} alt="up" />
                            <div className={style.square} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {width > 938 && (
                    <img
                      src={CartFormSeperator}
                      className={style.cartFormSeperator}
                      alt="seperator"
                    />
                  )}
                  <div className={style.total}>
                    <h4>
                      {LanguageData[currentLang].form.total}{' '}
                      {cartData
                        .map(i => parseFloat(i.price * i.quantity))
                        .reduce((a, b) => a + b)}{' '}
                      TL
                    </h4>
                    <div className={style.submitWrapper}>
                      {/* <button
                        className={style.ghostButton}
                        form="billing"
                        type="submit"
                      >
                        s
                      </button> */}
                      <button
                        form="payment"
                        type="submit"
                        className={style.buyButton}
                        // onClick={handleSubmit}
                      >
                        {LanguageData[currentLang].form.buyNow}
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Cart;
