import style from "./about.module.scss";
import { Header, Footer } from "../../components";
import Castle from "../../assets/img/home/castle.png";
import { LanguageData } from "../../utils/LanguageData";
import { useSelector } from "react-redux";

const About = () => {
  const currentLang = useSelector((state) => state.settings.language);
  return (
    <>
      <div className={style.about}>
        <Header about />
        <div className={style.content}>
          <img src={Castle} alt="castle" className={style.castle} />
          <div className={style.left}>
            <h1>
              {LanguageData[currentLang].about.left.title[1]}
              <span>{LanguageData[currentLang].about.left.title[2]}</span>
            </h1>
            <p>{LanguageData[currentLang].about.left.paragraph}</p>
            <h2>
              {currentLang === "en" ? (
                <>
                  {LanguageData[currentLang].about.left.subTitle[1]}{" "}
                  {LanguageData[currentLang].about.left.subTitle[3]}
                  <span>
                    {" "}
                    {LanguageData[currentLang].about.left.subTitle[2]}
                  </span>
                </>
              ) : (
                <>
                  {LanguageData[currentLang].about.left.subTitle[1]}{" "}
                  <span>
                    {LanguageData[currentLang].about.left.subTitle[2]}
                  </span>{" "}
                  {LanguageData[currentLang].about.left.subTitle[3]}
                </>
              )}
            </h2>
            <ul>
              {LanguageData[currentLang].about.left.list.map((item, index) => {
                return (
                  <li>
                    {index === 0 ? (
                      <h4>- I S T A N B U L -</h4>
                    ) : index === 5 ? (
                      <h4>- A N K A R A -</h4>
                    ) : index === 6 ? (
                      <h4>- İ Z M İ R -</h4>
                    ) : index === 7 ? (
                      <h4>- N E W Y O R K C I T Y -</h4>
                    ) : null}
                    <h5>{item.title}</h5>
                    <span>{item.text}</span>
                    {item.text2 ? (
                      <>
                        <br />
                        <span>{item.text2}</span>
                      </>
                    ) : null}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={style.right}>
            <h1>{LanguageData[currentLang].about.right.title}</h1>
            <p>
              <a
                href="mailto:danteveistakoz@gmail.com"
                target={"_blank"}
                rel="noreferrer"
              >
                danteveistakoz@gmail.com <br />
              </a>
              {LanguageData[currentLang].about.right.paragraph1}
              <br /> {LanguageData[currentLang].about.right.paragraph2}
              <br />
              <br /> {LanguageData[currentLang].about.right.paragraph3}{" "}
              <a
                href="https://www.instagram.com/danteveistakoz"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>{" "}
              {LanguageData[currentLang].about.right.paragraph4}
            </p>
            <div className={style.soundWrapper}>
              <a className={style.sound} href="/sesler">
                {LanguageData[currentLang].about.right.soundTitle}
              </a>
              <p>{LanguageData[currentLang].about.right.soundText}</p>
            </div>
            <h5>
              {LanguageData[currentLang].about.right.subTitle[1]}
              <span>
                {" "}
                {LanguageData[currentLang].about.right.subTitle[2]}
              </span>{" "}
              {LanguageData[currentLang].about.right.subTitle[3]}
            </h5>
            <p>
              {LanguageData[currentLang].about.right.paragraph[1]}
              <br />
              <br />
              {LanguageData[currentLang].about.right.paragraph[2]}
            </p>
            <a className={style.object} href="/nesneler">
              DANTE & ISTAKOZ
              <br /> {LanguageData[currentLang].about.right.object}
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
