import { Header, Footer } from '../../components';
import style from './shop.module.scss';
import { ShopData } from '../../data/ShopData';
import axios from 'axios';
import { BASE_URL, ApiRoutes } from '../../utils/ApiRoutes';
import { useEffect, useRef, useLayoutEffect } from 'react';
import { useState } from 'react';
import { slugify } from '../../helpers/Slugify';
import { LanguageData } from '../../utils/LanguageData';
import { useSelector } from 'react-redux';
import EyeIcon from '../../assets/img/shop/eye-icon.svg';
import useWindowDimensions from '../../helpers/windowWidth';
import Arrow from '../../assets/img/shop/next-item.svg';

const Shop = () => {
  const [data, setData] = useState([]);
  const { width } = useWindowDimensions();
  const currentLang = useSelector(state => state.settings.language);

  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  const onScroll = e => {
    setScrollTop(e.target.documentElement.scrollTop);
    setScrolling(e.target.documentElement.scrollTop > scrollTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
  }, []);

  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    console.log('scrolltop', scrollTop);
    console.log('dimensions', dimensions.height);
  }, [scrollTop]);

  useLayoutEffect(() => {
    if (targetRef.current && data) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      });
    }
  }, [data]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}${ApiRoutes.website.getProductList}`)
      .then(res => {
        console.log(res.data);
        setData(res.data.filter(i => i.productType === 'Book').reverse());
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className={style.shop}>
        <Header shop noCart={width < 938} />
        <div ref={targetRef} className={style.shopList}>
          {data &&
            data.map(item => {
              return (
                <a
                  className={style.shopItem}
                  href={`/shop/${slugify(item.title.tr)}?id=${item._id}`}
                >
                  <img src={item.files[0].fileUrl} alt={item.author} />
                  <div className={style.infoCircle}>
                    <h5>{item.title.tr}</h5>
                    <h6>{item.author}</h6>
                    <span>
                      {LanguageData[currentLang].shopDetail.letMeLook}
                    </span>
                  </div>
                </a>
              );
            })}
        </div>

        <button
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          style={{
            transform: `rotate(${
              scrollTop > dimensions.height - 600 ? '-90deg' : '90deg'
            })`,
          }}
          className={style.arrow}
        >
          <img src={Arrow} />
        </button>
      </div>
      <Footer />
    </>
  );
};

export default Shop;
