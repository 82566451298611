export const LanguageData = {
  tr: {
    header: {
      shop: {
        1: "YAYINLARA GÖZ ATACAĞIM",
        2: "VEYA SİPARİŞ VERECEĞİM",
      },
      about: {
        1: "DANTE VE ISTAKOZ",
        2: "NEDİR,NEREDE YAŞAR",
      },
      cart: "SEPET",
    },
    form: {
      shippingTitle: "TESLİMAT BİLGİLERİ",
      billingTitle: "FATURA BİLGİLERİ",
      name: "Ad ve Soyad:",
      tcNo: "TC Kimlik Veya Yabancı Kimlik No:",
      phone: "Telefon:",
      email: "E-Posta:",
      city: "Şehir:",
      shippingAddress: "Teslimat Adresi:",
      billingAddress: "Fatura Adresi:",
      buyNow: "SONRAKİ ÖDEME ADIMINA İLERLE",
      total: "Toplam",
      piece: "ADET",
      billingSame: "FATURA BİLGİLERİ TESLİMAT BİLGİLERİYLE AYNI",
      billingNotSame: "FARKLI FATURA BİLGİSİ GİRMEM GEREKİYOR",
      sizeData: "BEDEN",
      pleaseSelect: "Seçim Yapın",
    },
    footer: {
      about: "Hakkında",
      contact: "İletişim",
      socialMedia: "Sosyal Medya",
      importantLinks: "Önemli Bağlantılar",
      privacyPolicy: "Gizlilik Politikası",
      distance: "Mesafeli Satış Sözleşmesi",
      return: "Teslimat ve İade Politikası",
      aboutText: `Kenar, köşe ve içindekiler ile ilgilenen yayınlara alan açmayı
      hedefler, okyanusun dibinden beslenir, pişirildiğinde kırmızı
      rengini muhafaza eder.`,
    },
    shopDetail: {
      addToCart: {
        1: "SEPETE",
        2: "EKLE",
      },
      nextWork: {
        1: "SONRAKİ",
        2: "YAYIN",
      },
      cargo: "Kargo dahil",
      soldOut: "Tükendi",
      letMeLook: "İNCELEYEYİM?",
    },
    about: {
      left: {
        title: {
          1: "DANTE VE ISTAKOZ",
          2: "?",
        },
        paragraph: `Dante ve Istakoz, kenar, köşe ve içindekilerle ilgilenen yayınlara
          alan açmayı hedefler. Yayınlar; konu ve form olarak sanatçı
          kitabı, şiir, denemeyle ilgilenmek veya canları istemezse de
          hiçbiriyle ilgilenmemek üzere planlandı. Formda ve içerikte özgür
          oldukları için tasarımda da özgürler– bazı standartları ancak ölüm
          kalımına etki edeceği durumlarda önemsiyorlar. Dante ve
          Istakoz, hepsinden önemlisi, işbirliğine olanak tanıyan bir
          yayımlama mekanizmasına sahip. Şiir, performans, illüstrasyon,
          fotoğraf, elmalı turta tarifleri, farklı ayakkabı bağlama
          yöntemleri, her şey olabilir ancak büyük ölçüde basılı nesnelerden
          oluşuyor.`,
        subTitle: {
          1: "YAYINLARA FİZİKSEL DÜNYADA",
          2: "DA",
          3: "ULAŞILABİLİR",
        },
        list: [
          {
            title: "FilBooks",
            text: `Kemankeş Karamustafa Paşa Mah.
            Ali Paşa Değirmeni Sk. 1/1 Beyoğlu, İstanbul`,
          },
          {
            title: "Frankeştayn Bookstore",
            text: `Lüleci Hendek Caddesi 16A, 34425 Beyoğlu, İstanbul`,
          },
          {
            title: "Minoa Bookstore",
            text: `Vişnezade Mah. Süleyman Seba Caddesi 52/A 34357 Beşiktaş, İstanbul`,
          },
          {
            title: "Robinson Crusoe 389 Bookstore",
            text: `Asmalı Mescit, Salt Beyoğlu, İstiklal Cd. No:136 D:1,`,
            text2: `34433 Beyoğlu, İstanbul`,
          },
          {
            title: "Postane",
            text: `Bereketzade, Camekan Sk. No.9, 34421 Beyoğlu, İstanbul`,
          },
          {
            title: "Ka: Görsel Kültür ve Düşünce için Mekan",
            text: `Cinnah Caddesi 1/B, 06690 Çankaya, Ankara`,
          },
          {
            title: "Commentarivm",
            text: `Tershane Mah. 1662 Sok. 42/A`,
            text2: `35600 Karşıyaka, İzmir`,
          },
          {
            title: "Printed Matter [only selected works]",
            text: `231 11th Avenue, New York City, USA`,
          },
        ],
      },
      right: {
        title: "KONUŞABİLİRİZ",
        paragraph1: "Bir yayın fikriniz veya sorularınız varsa",
        paragraph2: "yukarıdaki e-posta adresine yazabilirsiniz.",
        paragraph3: "Dante ve Istakoz'un bir",
        paragraph4: "hesabı da var.",
        subTitle: {
          1: "DANTE VE ISTAKOZ’A",
          2: "DESTEK",
          3: "OLUNABİLİR",
        },
        soundTitle: `DANTE & ISTAKOZ SESLER`,
        soundText: `Konuşmalar serisi de
        soldaki linkten dinlenebilir.`,
        paragraph: {
          1: `Dante ve Istakoz yayınlarını üretmek için zaman zaman sponsor
            desteği alıyor. Bunun için e-posta adresine yazabilirsiniz.`,
          2: `Bunun dışında küçük ölçekte destekler için Dante ve Istakoz
            nesnelerine göz atabilirsiniz:`,
        },
        object: "NESNELER",
      },
    },
    sound: {
      text1: "Kitabın İçi ve Dışı : Tasarım ve",
      text2: `İçerik İlişkisi Üzerine Sohbet`,
      text3: `Postane Beyoğlu, 27.03.2023`,
    },
    sound2: {
      text1:
        "Delfin Lev ile Kitabı 3 AM Exodus Hakkında Türkçe ve İngilizce bir Podcast",
      text2: `Dante ve Istakoz'un ilk kitabı "3 AM Exodus" hakkında yazarı Delfin Lev ile,`,
      text3: "ilk yarısı Türkçe ikinci yarısı İngilizce bir podcast.",
    },
  },
  en: {
    header: {
      shop: {
        1: "I WOULD LIKE TO BROWSE",
        2: "OR PURCHASE PUBLICATIONS",
      },
      about: {
        1: "WHATS AND WHYS ABOUT",
        2: "DANTE VE ISTAKOZ",
      },
      cart: "CART",
    },
    form: {
      shippingTitle: "SHIPPING DETAILS",
      billingTitle: "INVOICE",
      name: "Name and Surname:",
      tcNo: "TR Identity or Foreigner Identity Number:",
      phone: "Phone:",
      email: "E-Mail:",
      city: "City:",
      shippingAddress: "Shipping Address:",
      billingAddress: "Billing Address:",
      buyNow: "PROCEED TO THE NEXT PAYMENT STEP",
      total: "Total",
      piece: "QUANTITY:",
      billingSame:
        "The Name and the Address for the Invoice is Same as Delivery",
      billingNotSame:
        "The Name and the Address for the Invoice is Different Than Delivery",
      sizeData: "SIZE",
      pleaseSelect: "Please Select",
    },
    footer: {
      about: "About",
      contact: "Contact",
      socialMedia: "Social Media",
      importantLinks: "Important Links",
      privacyPolicy: "Privacy Policy",
      distance: "Distance Sale Policy",
      return: "Return Policy",
      aboutText: `Dante ve Istakoz aims to make room for publications that are interested in sides, corners and those in between; feeds from the bottom of the ocean; preserves its natural red color after being cooked.`,
    },
    shopDetail: {
      addToCart: {
        1: "ADD TO",
        2: "CART",
      },
      nextWork: {
        1: "NEXT",
        2: "PUBLICATION",
      },
      cargo: "-Shipping included",
      soldOut: "Sold Out",
      letMeLook: "LET ME LOOK",
    },
    about: {
      left: {
        title: {
          1: "DANTE VE ISTAKOZ",
          2: "?",
        },
        paragraph: `Dante ve Istakoz aims to make room for publications that are interested in sides, corners and those in between. The form and the content of the publications could be anything from artist books, poetry to essays, performance, illustration, photography, apple pie recipes to tips for tying your shoe laces and more. However, they are mostly printed objects.`,
        subTitle: {
          1: "WHERE TO FIND THE PUBLICATIONS",
          2: "PHYSICAL WORLD",
          3: "IN THE",
        },
        list: [
          {
            title: "FilBooks",
            text: `Kemankeş Karamustafa Paşa Mah.
            Ali Paşa Değirmeni Sk. 1/1 Beyoğlu, İstanbul`,
          },
          {
            title: "Frankeştayn Bookstore",
            text: `Lüleci Hendek Caddesi 16A, 34425 Beyoğlu, İstanbul`,
          },
          {
            title: "Minoa Bookstore",
            text: `Vişnezade Mah. Süleyman Seba Caddesi 52/A 34357 Beşiktaş, İstanbul`,
          },
          {
            title: "Robinson Crusoe 389 Bookstore",
            text: `Asmalı Mescit, Salt Beyoğlu, İstiklal Cd. No:136 D:1,`,
            text2: `34433 Beyoğlu, İstanbul`,
          },
          {
            title: "Postane",
            text: `Bereketzade, Camekan Sk. No.9, 34421 Beyoğlu, İstanbul`,
          },
          {
            title: "Ka: Görsel Kültür ve Düşünce için Mekan",
            text: `Cinnah Caddesi 1/B, 06690 Çankaya, Ankara`,
          },
          {
            title: "Commentarivm",
            text: `Tershane Mah. 1662 Sok. 42/A`,
            text2: `35600 Karşıyaka, İzmir`,
          },
          {
            title: "Printed Matter [only selected works]",
            text: `231 11th Avenue, New York City, USA`,
          },
        ],
      },
      right: {
        title: "WE CAN TALK",
        paragraph1: "You can submit your publication idea or",
        paragraph2: "your questions to the above address.",
        paragraph3: "Dante ve Istakoz has an",
        paragraph4: "account.",
        subTitle: {
          1: "ONE CAN",
          2: "SUPPORT",
          3: "DANTE VE ISTAKOZ",
        },
        soundTitle: `DANTE & ISTAKOZ SOUNDS`,
        soundText: `Dante ve Istakoz Sounds Series
        can be listened from the link on the left.`,
        paragraph: {
          1: `Dante ve Istakoz often seeks out sponsors to cover printing costs of its upcoming publications. You can reach out to sponsor a publication.`,
          2: `You can also have a look at Dante 
          ve Istakoz Objects from the
          link below:`,
        },
        object: "OBJECTS",
      },
    },
    sound: {
      text1: "Inside and Outside the Book : Design and",
      text2: `Conversation on Content Relationship`,
      text3: `Postane Beyoğlu, 27.03.2023`,
    },
    sound2: {
      text1:
        "With Delfin Lev on Her Book 3 AM Exodus, A Podcast in Turkish and English",
      text2: `18th minute mark for the English portion of the podcast.`,
      text3: "",
    },
  },
};
