import style from './shopdetail.module.scss';
import { Header, Footer } from '../../components';
import { useLocation } from 'react-router-dom';
import { ShopData } from '../../data/ShopData';
import Slider from 'react-slick';
import CartIcon from '../../assets/img/shop/cart-icon.svg';
import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../redux/actions';
import NextItem from '../../assets/img/shop/next-item.svg';
import axios from 'axios';
import { ApiRoutes, BASE_URL } from '../../utils/ApiRoutes';
import { slugify } from '../../helpers/Slugify';
import { LanguageData } from '../../utils/LanguageData';
import { useSelector } from 'react-redux';
import useWindowDimensions from '../../helpers/windowWidth';

const ShopDetail = () => {
  const location = useLocation();
  const currentLocation = location.pathname.split('/')[2];
  const currentId = location.search.split('=')[1];
  const currentData = ShopData.filter(i => i.path === currentLocation)[0];
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef();
  const dispatch = useDispatch();
  const [nextItem, setNextItem] = useState();
  const [data, setData] = useState();
  const [allData, setAllData] = useState([]);
  const cartData = useSelector(state => state.cart).cart;
  const [error, setError] = useState({
    status: false,
    show: false,
  });
  const { width } = useWindowDimensions();

  const settings = {
    dots: false,
    nextArrow: <></>,
    prevArrow: <></>,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  useEffect(() => {
    axios
      .post(`${BASE_URL}${ApiRoutes.website.getProduct}`, { id: currentId })
      .then(res => {
        console.log(res.data);
        setData(res.data);
      })
      .catch(err => {
        console.log(err);
      });

    axios
      .get(`${BASE_URL}${ApiRoutes.website.getProductList}`)
      .then(res => {
        console.log(res.data);
        const filteredData = res.data
          .filter(i => i.productType === 'Book')
          .reverse();
        setAllData(filteredData);

        const currentIndex = filteredData.findIndex(i => i._id === currentId);

        if (currentIndex === 0 || currentIndex + 1 !== filteredData.length) {
          setNextItem({
            id: filteredData[currentIndex + 1]._id,
            path: slugify(filteredData[currentIndex + 1].title.tr),
          });
          console.log(filteredData[currentIndex + 1]._id);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const currentLang = useSelector(state => state.settings.language);

  useEffect(() => {
    if (data) {
      const findInCart = cartData.findIndex(item => item.id === data._id);
      if (findInCart >= 0 && cartData[findInCart].quantity === 3) {
        setError({
          ...error,
          status: true,
        });
      }
    }
  }, [cartData, data]);

  return (
    <>
      <div className={style.shopDetail}>
        <Header shop />
        {data && (
          <div className={style.content}>
            {nextItem ? (
              <a
                href={`/shop/${nextItem.path}?id=${nextItem.id}`}
                className={style.nextItem}
              >
                <img src={NextItem} alt="next-item" />
              </a>
            ) : null}
            <div className={style.imageWrapper}>
              <Slider ref={sliderRef} className={style.slider} {...settings}>
                {data.files.map(item => {
                  return (
                    <div className={style.sliderCard}>
                      <img src={item.fileUrl} alt="slider" />
                    </div>
                  );
                })}
              </Slider>
              <div className={style.sliderButton}>
                {[...new Array(data.files.length)].map((item, index) => {
                  return (
                    <button
                      onClick={() => {
                        sliderRef.current.slickGoTo(index);
                      }}
                      className={index === currentSlide && style.active}
                    />
                  );
                })}
              </div>
              {nextItem && width < 938 ? (
                <a
                  href={`/shop/${nextItem.path}?id=${nextItem.id}`}
                  className={style.nextItemMobile}
                >
                  <img src={NextItem} alt="next-item" />
                  <span>
                    {LanguageData[currentLang].shopDetail.nextWork[1]}{' '}
                    {LanguageData[currentLang].shopDetail.nextWork[2]}
                  </span>
                </a>
              ) : null}
            </div>
            <div className={style.info}>
              <div className={style.infoTop}>
                <h1>{data.title[currentLang]}</h1>
                <h2>{data.author}</h2>
                <p>{data.info[currentLang]}</p>
                <div
                  className={style.descriptionInner}
                  dangerouslySetInnerHTML={{
                    __html: data.description[currentLang],
                  }}
                />

                <h5>
                  {currentLang === 'en' ? (
                    <div>
                      {' '}
                      {data.price} TL{' '}
                      <span>{LanguageData[currentLang].shopDetail.cargo}</span>{' '}
                    </div>
                  ) : (
                    <div>
                      {' '}
                      <span>
                        {LanguageData[currentLang].shopDetail.cargo}
                      </span>{' '}
                      {data.price} TL
                    </div>
                  )}
                </h5>
              </div>

              <div className={style.cartWrapper}>
                {error.status && error.show ? (
                  <h6>
                    En fazla 3 adet ekleyebilirsiniz. Daha fazla almak için:
                    danteveistakoz@gmail.com'a mail atabilirsiniz.
                  </h6>
                ) : null}
                <button
                  onClick={() => {
                    if (error.status) {
                      setError({
                        ...error,
                        show: true,
                      });
                    }

                    if (data.isLive) {
                      dispatch(
                        addToCart({
                          id: data._id,
                          title: data.title.tr,
                          author: data.author,
                          price: data.price,
                          coverImage: data.files[0].fileUrl,
                          // edition: currentData.edition,
                          quantity: 1,
                        })
                      );
                    }
                  }}
                >
                  {data.isLive ? (
                    <>
                      {LanguageData[currentLang].shopDetail.addToCart[1]} <br />{' '}
                      {LanguageData[currentLang].shopDetail.addToCart[2]}
                    </>
                  ) : (
                    LanguageData[currentLang].shopDetail.soldOut
                  )}
                </button>
                <img src={CartIcon} alt="cart" />
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ShopDetail;
