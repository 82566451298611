import Gizlilik from '../../assets/img/static/gizlilik.svg';
import Mesafeli from '../../assets/img/static/mesafeli.svg';
import Teslimat from '../../assets/img/static/teslimat.svg';
import { useLocation } from 'react-router-dom';
import style from './textpage.module.scss';
import { Header, Footer } from '../../components';

const data = [
  {
    mainTitle: 'MESAFELİ SATIŞ SÖZLEŞMESİ',
    image: Mesafeli,
    link: '/mesafeli-satis-sozlesmesi',
    textData: `<h2>1. Sözleşmenin Tarafları</h2>
    İş bu Sözleşme; 
    <br/><br/>
    Satıcı
    Unvan: Cogita-Bilişim Tanıtım Mühendislik Taahhüt Danışmanlık Turizm ve Ticaret Sanayi Anonim Şirketi
    (kısaca“Satıcı" olarak anılacaktır.)
    Mersis No: 0211 068 4467 00001
    Tebligat Adresi: Üniversiteler Mahallesi 1597. Cadde Kümeevler No:62 D:2 Bilkent Çankaya Ankara
    E-posta: danteveistakoz@gmail.com
    <br/><br/>
    ile
    <br/><br/>
    Müşteri
    (kısaca “Müşteri" olarak anılacaktır.)
     <br/><br/>
    (İşbu Sözleşme’de Satıcı ve Müşteri her biri ayrı ayrı “Taraf” ve beraberce “Taraflar” olarak adlandırılacaktır.)
    <br/><br/>
    <h2>2. Tanımlar</h2>
    <br/><br/>
    İşbu sözleşmenin uygulanmasında ve yorumlanmasında aşağıdaki yazılı terimler karşılarında yazılı olan açıklamaları ifade edecektir.
    <br/><br/>
    BAKANLIK: Türkiye Cumhuriyeti Ticaret Bakanlığı’nı,
     <br/><br/>
    KANUN: 6502 sayılı Tüketicinin Korunması Hakkında Kanun’u,
     <br/><br/>
    YÖNETMELİK: 27.11.2014 Tarihli 29188 sayılı Mesafeli Sözleşmeler Yönetmeliği’ni
     <br/><br/>
    ÜRÜN: Alışverişe konu Dante ve Istakoz markası adı altında satılacak olan yayınlar ve benzeri nesneler
     <br/><br/>
    SATICI: Ticari veya mesleki faaliyetleri kapsamında tüketiciye mal sunan şirketi,
     <br/><br/>
    MÜŞTERİ: Bir mal veya hizmeti ticari veya mesleki olmayan amaçlarla edinen, kullanan veya yararlana gerçek ya da tüzel kişiyi,     
     <br/><br/>
    SİTE: Satıcı’ya ait internet sitesini,
     <br/><br/>
    SİPARİŞ VEREN: Bir mal veya hizmeti Satıcı’ya ait internet sitesi üzerinden talep eden gerçek ya da tüzel kişiyi,
     <br/><br/>
    TARAFLAR: Satıcı ve Müşteri’yi,
     <br/><br/>
    SÖZLEŞME: Satıcı ve Müşteri arasında akdedilen mesafeli satışlar sözleşmesini ifade eder.
    <br/><br/>
    <h2>3. Sözleşmenin Konusu</h2>
    <br/><br/>
    3.1. İşbu Sözleşme’nin konusu, Müşteri’nin Satıcı’ya ait 
    danteveistakoz.com web sitesinden (“Site”) elektronik ortamda sipariş verdiği, Sözleşme’de bahsi geçen nitelikleri haiz ve yine Sözleşme’de satış fiyatı belirtilen ürünlerin (“Ürün/Ürünler”) satışı ve teslimi ile ilgili olarak 6502 sayılı Tüketicinin Korunması Hakkında Kanun (“Kanun”) ve 29188 sayılı Mesafeli Sözleşmeler Yönetmeliği (“Yönetmelik”) hükümleri gereğince Taraflar’ın hak ve yükümlülüklerinin düzenlenmesidir.
    <br/><br/>
    3.2. Listelenen ve sitede ilan edilen fiyatlar satış fiyatıdır. İlan edilen fiyatlar sitede güncelleme yapılana ve değiştirilene kadar geçerlidir. Süreli olarak ilan edilen fiyatlar ise belirtilen süre sonuna kadar geçerlidir.
    <br/><br/>
    <h2>4. Sözleşmeye Konu Ürün veya Hizmet, Ödeme ve Teslimat</h2>
    <br/><br/>
    İşbu Sözleşme’nin konusunu oluşturan Ürün veya Ürünler’in cinsi, miktarı, markası, modeli, rengi ve KDV dahil satış bedeli aşağıda belirtildiği gibidir:
    <br/><br/>
    <h2>5. Tarafların Beyan, Hak ve Yükümlülükleri</h2>
    <br/><br/>
    5.1. Müşteri, işbu Sözleşme konusu Ürün veya Ürünler’in KDV dahil satış fiyatı ve teslimat masraflarının Müşteri tarafından karşılanacağını, Satıcı’ın tam ticari unvanı, açık adresi ve iletişim bilgilerine ilişkin ön bilgilendirme formunu okuyup doğru ve eksiksiz olarak bilgi sahibi olduğunu ve elektronik ortamda gerekli onayı verdiğini kabul, beyan ve taahhüt eder.
    <br/><br/>
    5.2. Müşteri, satışa konu Ürün veya Ürünler’in temel nitelikleri, satış fiyatı, ödeme şekli, teslimat koşulları ve sair tüm ön bilgiler ve cayma hakkı konusunda bilgi sahibi olduğunu, bu ön bilgileri elektronik ortamda teyit ettiğini ve sonrasında Ürün veya Ürünler’in siparişini
    verdiğini işbu Sözleşme hükümlerince kabul, beyan ve taahhüt eder.
    <br/><br/>
    5.3. Ürün veya Ürünler’in teslimatı; stokun müsait olması ve bedelinin Satıcı’ın hesabına geçmesinden sonra en kısa sürede yapılır. Satıcı, siparişinden itibaren 30 (Otuz) günü aşmamak kaydıyla Müşteri’nin yerleşim yeri uzaklığına bağlı olarak Ürün veya Ürünler’i Müşteri’nin işbu Sözleşme’de belirtilen teslimat adresine teslim edeceğini kabul, beyan ve taahhüt eder.
     <br/><br/>
    5.4. Satıcı, Sözleşme konusu Ürün veya Ürünler’in, eksiksiz, siparişte belirtilen niteliklere uygun ve varsa garanti belgeleri ve kullanım kılavuzları, işin gereği olan bilgi ve belgeler ile Müşteri’ye teslim edilmesinden sorumlu olduğunu kabul, beyan ve taahhüt eder.
    <br/><br/>
    5.5. Satıcı, kendisi belirleyerek Site’de ilan ettiği rakamın üzerinde alışveriş yapanların teslimat ücretinin kendisince karşılanacağını ya da kampanya dahilinde ücretsiz teslimat yapacağını beyan etmişse, teslimat masrafı Satıcı’ya aittir. Ancak ücretsiz teslimata ilişkin
    beyanda bulunulmamışsa masraflar Müşteri tarafından karşılanacaktır.
    <br/><br/>
    5.6. Müşteri, Sözleşme konusu Ürün veya Ürünler’i teslimat sırasında ancak teslim almadan önce muayene edecek; ezik, kırık, ambalajı yırtılmış ve benzeri hasarlı ve ayıplı Ürün veya Ürünleri kargo şirketinden teslim almayacaktır.
    <br/><br/>
    5.7. Müşteri, kargo şirketinden teslim aldığı Ürün veya Ürünler’in kendisine eksiksiz, hasarsız ve sağlam olarak teslim edildiğini kabul, beyan ve taahhüt eder. Teslimden sonra Ürün veya Ürünler’in özenle korunması borcu, Müşteri’ye aittir. Cayma hakkı kullanılacaksa söz konusu Ürün veya Ürünler kullanılmamalıdır. Fatura da iade edilmelidir. Cayma hakkı kullanılarak iade edilen Ürün veya Ürünler kullanılmışsa Satıcı’nın Ürün veya Ürünler’in iadesini kabul etmeme hakkı saklıdır.
    <br/><br/>
    5.8. Ürün veya Ürünler’in tesliminden sonra Müşteri’ye ait kredi kartının Müşteri’nin kusurundan kaynaklanmayan bir şekilde yetkisiz kişilerce haksız veya hukuka aykırı olarak kullanılması nedeniyle ilgili banka veya finans kuruluşunun Ürün veya Ürünler’in bedelini
    Satıcı’ya ödememesi halinde, Müşteri kendisine teslim edilmiş olan Ürün veya Ürünleri 3 (Üç) gün içinde Satıcı’ya göndermekle yükümlü olduğunu, bu durumda oluşacak teslimat giderlerinin Müşteri’ye ait olduğunu kabul, beyan ve taahhüt eder.
    <br/><br/>
    5.9. Herhangi bir nedenle Ürün veya Ürünler’in bedeli ödenmez veya banka kayıtlarında iptal edilir ise, Satıcı işbu Sözleşme konusu Ürün veya Ürünler’in teslimi yükümlülüğünden kurtulmuş kabul edilir.
    <br/><br/>
    5.10. Taraflar, Ürün veya Ürünler’in Müşteri’den başka bir kişi/kuruluşa teslim edilecek olması durumunda, teslim edilecek kişi/kuruluşun teslimatı kabul etmemesinden Satıcı’nın sorumlu olmadığını kabul, beyan ve taahhüt eder.
    <br/><br/>
    5.11. Satıcı Ürün veya Ürünler’in ifasının imkânsızlaştığını düşünüyorsa, Sözleşme’nin ifa süresi dolmadan Müşteri’ye bildirir. Ödenen bedel ve varsa belgeler 10 (On) iş günü içerisinde Müşteri’ye iade edilir.
    <br/><br/>
    5.12. Teslimatı yapacak olan kargo firmasının, Ürün veya Ürünler’i Müşteri’ye teslimi aşamasında karşılaşacağı her türlü sorun nedeniyle, siparişi verilen Ürün veya Ürünler’in Müşteri'nin işbu Sözleşme’de belirtilen teslimat adresine teslim edilememesinden dolayı Satıcı sorumlu tutulamaz.
    <br/><br/>
    5.13. Satıcı, mücbir sebepler veya teslimatı engelleyen hava muhalefeti, ulaşımın kesilmesi gibi olağanüstü durumlar nedeni ile Sözleşme konusu Ürün veya Ürünler’i süresi içinde teslim edemez ise, durumu Müşteri'ye bildirmekle yükümlüdür. Bu takdirde Müşteri siparişin iptal edilmesini, Ürün veya Ürünler’in varsa emsali ile değiştirilmesini ve/veya teslimat süresinin engelleyici durumun ortadan kalkmasına kadar ertelenmesi haklarından birini kullanabilir.
    <br/><br/>
    Müşteri'nin siparişi iptal etmesi halinde ödediği tutar 10 gün içinde kendisine nakden ve defaten ödenir.
    <br/><br/>
    5.14. Müşterinin, kredi kartı ile ve taksitle alışveriş yapması durumunda işbu Sözleşme’de belirtilmiş olan taksit biçimi geçerlidir.
    <br/><br/>
    5.15. Müşteri’nin kredi kartı ile yaptığı ödemelerde, ürün tutarı, siparişin Müşteri tarafından iptal edilmesinden sonra 7 gün içerisinde ilgili bankaya iade edilir. Bu tutarın bankaya iadesinden sonra Müşteri hesaplarına yansıması tamamen banka işlem süreci ile ilgili olup, Satıcı’nın bu hususa ilişkin herhangi bir şekilde müdahalede bulunması mümkün olamamaktadır.
    <br/><br/>
    5.16. Müşteri’nin sitede kullanmak üzere kazandığı kupon kodlarını her siparişi için sadece 1 defa kullanabilir. 2 veya daha fazla kullanılması durumunda siparişin iptali sağlanıp ödenen tutar iade edilir.
    <br/><br/>
    5.17. Satıcı gerekli gördüğü durumlarda, Müşteri’nin vermiş olduğu bilgiler gerçekle örtüşmediğinde, siparişi durdurma hakkını saklı tutar. Satıcı siparişte sorun tespit ettiği durumlarda Müşteri’nin vermiş olduğu telefon, e-posta ve posta adreslerinden Müşteri’ye ulaşamadığı takdirde siparişin yürürlüğe koyulmasını 15 (onbeş) gün süreyle dondurur. Müşteri’ den bu süre zarfında Satıcı ile konuyla ilgili olarak iletişime geçmesi beklenir. Bu süre içerisinde Müşteri’ den herhangi bir cevap alınamazsa Satıcı, her iki tarafın da zarar görmemesi için siparişi iptal eder.
    <br/><br/>
    5.18. Kampanyalı ürünle, sadece kampanya dahilindeki ürün veya ürünler ile değişimi yapılacaktır. Kampanyalı bir ürünle kampanyalı olmayan ürün veya ürünler içerisinden değişiklik yapılmak istenir ise söz konusu husus kampanya kapsamında olmayacağından Müşteri’ de kalan kampanyalı diğer ürün/ürünler sezon fiyatı ile değerlendirilecektir ve geriye kalan tutar kampanya dışı kategoriden değiştirilecektir
     <br/><br/>
    5.19. Kampanyalı ürünün iadesi işlemlerinde, Müşteri’nin satın almış olduğu kampanyaya dahil olan tüm ürünlerin Satıcı’ya gönderilmesi zorunludur. Eğer kampanyalı ürün veya ürünlerin iadesi için sadece tek ürün Satıcı’ya gönderildiyse; kampanya kapsamından çıkılacağından Müşteri’ de kalan ürünler sezon fiyatı ile değerlendirilecektir ve geriye kalan tutarın iadesi gerçekleştirilecektir
    <br/><br/>
    5.20. İşbu sözleşme içerisindeki hükümleri ihlal eden Müşteri işbu ihlal nedeniyle cezai ve hukuki olarak şahsen sorumlu olacaktır. Satıcı’yı bu ihlallerin hukuki ve cezai sonuçlarından yükümlü olmayacaktır. Ayrıca, işbu ihlal nedeniyle Satıcı’nın her türlü dava ve şikayet hakkı saklıdır.
    <br/><br/>
    <h2>6. Cayma Hakkı</h2>
    <br/><br/>
    6.1. Müşteri, Ürün veya Ürünler’in işbu Sözleşme’de belirtilen teslimat adresine tesliminden itibaren 14 (On Dört) gün içinde hiçbir sebep göstermek zorunda olmaksızın cayma hakkına sahiptir. Buna mukabil, Müşteri’nin ürün değişimi isteme hakkı bulunmamaktadır. Müşteri’nin üründen memnun olmaması durumunda cayma hakkını kullanarak ürün iade prosedürünü işletmesi gerekmektir. Müşteri, ürün değişimi yapılmayacağını bildiğini peşinen kabul, beyan ve taahhüt eder. 
    <br/><br/>
    6.2. Cayma hakkının kullanılması için 14 (On Dört) günlük süre içinde Satıcı'ya e-posta ile bildirimde bulunulması ve iade edilmek istenen Ürün veya Ürünler’in işbu Sözleşme’nin 6. maddesi hükümleri çerçevesinde kullanılmamış ve Satıcı tarafından tekrar satışa arz edilebilir nitelikte olması şarttır. Cayma hakkının kullanılması halinde:
     <br/><br/>
    1. Müşteri’nin işbu Sözleşme’de belirttiği teslimat adresine teslim edilen Ürün veya Ürünler’in faturası, (İade edilmek istenen Ürün veya Ürünler’in faturası kurumsal ise, iade ederken kurumun düzenlemiş olduğu iade faturası ile birlikte gönderilmesi gerekmektedir. Faturası kurumlar adına düzenlenen sipariş iadeleri İADE FATURASI kesilmediği takdirde tamamlanamayacaktır)
    <br/><br/>
    2. İade için e-posta,
    <br/><br/>
    3. Ürün veya Ürünler’in kutusu, ambalajı, varsa standart aksesuarları ile birlikte eksiksiz ve hasarsız olarak Satıcı’ya teslim edilmesi gerekmektedir.
    <br/><br/>
    6.3. Madde 6.2’ de sayılanların Satıcı’ ya ulaşmasını takip eden 10 (On) iş günü içinde Ürün veya Ürünler’in bedeli Müşteri'ye 
    iade edilir.
    <br/><br/>
    6.4. Süresi içerisinde herhangi bir sebeple iade edilen Ürün veya Ürünler’in Müşteri’ye gönderim de dahil tüm kargo bedeli Satıcı tarafından karşılanacaktır. Kapıda ödeme hizmeti ile yapılan ürün gönderimlerinin iadesinde kargo firması tarafından ürün tutarının tahsilatı için alınan hizmet bedeli iade edilmez.
    <br/><br/>
    6.5. Ürün veya Ürünler Satıcı’ya iade edilirken, teslim sırasında Müşteri’ye ibraz edilmiş olan orijinal faturanın da Satıcı’ya iade edilmesi gerekmekte olup, faturanın Ürün veya Ürünler ile birlikte ya da en geç Ürün veya Ürünler’in gönderilmesinden itibaren 5 (Beş) gün içerisinde Satıcı’ya gönderilmemesi durumunda iade işlemi gerçekleştirilmeyecek, Ürün veya Ürünler aynı şekilde karşı ödemeli olarak Müşteri’ye geri gönderilecektir.
    <br/><br/>
    6.6. İade edilecek olan faturanın üzerine de "iade faturasıdır" ibaresi yazılıp Müşteri tarafından imzalanacaktır.
     <br/><br/>
    6.7. Cayma hakkının kullanılması da dahil olmak üzere, nakit veya havale ile gerçekleştirilen satış işlemlerinde bedel iadesi Müşteri’nin siparişin verilmesinde kullanacağı banka hesabına yapılır. Bunun haricinde Müşteri, Satıcı’nın işyerine aslen gelip iade formuna yazılı olarak siparişin verilmesinde kullanılandan farklı bir hesabı beyan etmediği takdirde, telefon, e-posta veya diğer iletişim kanallarıyla bedel iadesinin siparişin verilmesinde kullanılan hesap haricinde bir kişinin banka hesabına yapılmasını talep edemez. Müşteri, birinci derece akrabaları da dahil olmak üzere Satıcı’nın üçüncü kişi hesabına bedel iadesi yapmaktan imtina etme hakkını kabul eder.    
      <br/><br/>
    <h2>7. Cayma Hakkı Kullanılamayacak Ürünler</h2>
    <br/><br/>
    Cayma hakkı; tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış olan mallarda; iadesi sağlık ve hijyen açısından uygun olmayan ürünlerde, kullanılamaz.
    <br/><br/>
    <h2>8. Mücbir Sebep</h2>
    <br/><br/>
    8.1. Sözleşmenin imzalandığı tarihte mevcut olmayan veya öngörülmeyen, tarafların kontrolleri dışında gelişen, ortaya çıkmasıyla taraflardan birinin ya da her ikisinin de sözleşme ile yüklendikleri borç ve sorumluluklarını kısmen ya da tamamen yerine getirmelerini ya da bunları zamanında yerine getirmelerini olanaksızlaştıran durumlar, mücbir sebep (Doğal afet, savaş, terör, ayaklanma, değişen mevzuat hükümleri, el koyma veya grev, lokavt, üretim ve iletişim tesislerinde önemli ölçüde arıza vb.) olarak kabul edilecektir. Mücbir sebep şahsında gerçekleşen taraf, diğer tarafa durumu derhal ve yazılı olarak bildirecektir.
     <br/><br/>
    8.2. Mücbir sebebin devamı esnasında tarafların edimlerini yerine getirememelerinden dolayı herhangi bir sorumlulukları doğmayacaktır. İşbu mücbir sebep durumu 30 (otuz) gün süreyle devam ederse, taraflardan her birinin, tek taraflı olarak fesih hakkı doğmuş olacaktır.
    <br/><br/>
    <h2>9. Temerrüt Hali ve Sonuçları</h2>
    <br/><br/>
    Müşteri, ödeme işlemlerini kredi kartı ile yaptığı durumda temerrüde düştüğü takdirde, kart sahibi banka ile arasındaki kredi kartı sözleşmesi çerçevesinde faiz ödeyeceğini ve bankaya karşı sorumlu olacağını kabul, beyan ve taahhüt eder. Bu durumda ilgili banka hukuki yollara başvurabilir; doğacak masrafları ve vekâlet ücretini Müşteri’den talep edebilir ve her koşulda Müşteri’nin borcundan dolayı temerrüde düşmesi halinde, Müşteri, borcun gecikmeli ifasından dolayı Satıcı’nın uğradığı zarar ve ziyanını ödeyeceğini kabul, beyan ve taahhüt eder.
    <br/><br/>
    <h2>10. Yetkili Mahkeme</h2>
    <br/><br/>
    İşbu sözleşmeden kaynaklanan ihtilaflarda, Ticaret Bakanlığı tarafından ilan edilen değere kadar Müşteri’nin yerleşim yerindeki Tüketici Hakem Heyetleri ile bu değerlerin üzerindeki uyuşmazlıklar için Müşteri’nin yerleşim yerindeki Tüketici Mahkemeleri ve İcra Daireleri yetkilidir.
    <br/><br/>
    <h2>11. Bildirimler</h2>
    <br/><br/>
    Bu Sözleşme’ye göre yapılacak tüm bildirim, ihbar ve tebligatlar
    e-posta, açıklamalı kargo gönderisi, iadeli taahhütlü mektupla veya noter aracılığı ile Tarafların işbu Sözleşmenin 1. maddesinde belirtilen adreslerine yapılacaktır. Taraflar Sözleşmede belirtilen adreslerin
    kanuni tebligat adresleri olduğu, adres değişikliği karşı tarafa beş (5) iş günü önceden bildirilmedikçe bu adreslere yapılan tebligatların geçerli olacağı hususlarında mutabıktırlar. Satıcı tarafından yapılan bu Sözleşme kapsamındaki tüm bildirimler yollanmasından 1 (bir) gün sonra Müşteri’ye ulaştığı kabul edilecek ve Müşteri tarafından Satıcı’ya gönderilecek bu Sözleşme kapsamındaki tüm bildirimler Türk Kanunlarına göre tebliğ edilmiş sayıldıkları günden itibaren geçerli olacaklardır.
    <br/><br/>
    <h2>12. Son Hükümler</h2>
    <br/><br/>
    12.1. İşbu Sözleşme 12 (On iki) maddeden ibaret olup, Taraflar’ca tarihinde akdedilmiştir. http://www.danteveistakoz.com web sitesinin ödeme sayfasında yer alan ön bilgilendirme formu ve fatura işbu Sözleşme’nin ayrılmaz parçalarıdır. Taraflar, işbu Sözleşme’nin tamamını okuduklarını, kabul ettiklerini, burada kendilerince belirtilen bilgilerin doğru olduğunu ve tüm sözleşme hükümlerinin geçerli olduğunu kabul, beyan ve taahhüt eder. Site’nin özel olarak düzenlenen alt yapısı ile işbu Sözleşme’yi onaylayan Müşteri, Sözleşme’deki tüm koşulları kabul etmiş sayılır.
     <br/><br/>
    12.2. Müşteri, site üzerinden sipariş vermiş olduğu Ürün veya Ürün’lere ait ödemeyi gerçekleştirdiğinde işbu sözleşmenin tüm şartlarını kabul etmiş sayılır. 
    <br/><br/>
    Satıcı: Cogita A.Ş.
    Müşteri:`,
  },
  {
    mainTitle: 'GİZLİLİK POLİTİKASI',
    image: Gizlilik,
    link: '/gizlilik-politikasi',
    textData: `<h2>Kişisel Veriler Kanunu Hakkında Genel Bilgilendirme:</h2>
    <br/><br/>
    6698 Sayılı Kişisel Verilerin Korunması Kanunu (bundan sonra KVKK olarak anılacaktır) 24 Mart 2016 tarihinde kabul edilmiş, 7 Nisan 2016 tarihli 29677 sayılı Resmi Gazete’de (bundan sonra RG) yayımlanmıştır. KVKK’nın bir kısmı yayın tarihinde, bir kısmı ise 7 Ekim 2016’da yürürlüğe girmiştir.
    <br/><br/>
    <h2>Veri Sorumlusu Sıfatıyla Bilgilendirme:</h2>
    <br/><br/>
    6698 sayılı KVKK uyarınca ve Veri Sorumlusu sıfatıyla, kişisel verileriniz bu sayfada açıklandığı çerçevede; kaydedilecek, saklanacak, güncellenecek, mevzuatın izin verdiği durumlarda 3. kişilere açıklanabilecek/devredilebilecek, sınıflandırılabilecek ve KVKK’da sayılan şekillerde işlenebilecektir.
    <br/><br/>
    <h2>Kişisel Verilerinizin İşlenme Biçimlerine Dair:</h2>
    <br/><br/>
    6698 sayılı KVKK uyarınca, Firmamız ile paylaştığınız kişisel verileriniz, tamamen veya kısmen, otomatik olarak, veyahut herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilerek, kaydedilerek, depolanarak, değiştirilerek, yeniden düzenlenerek, kısacası veriler üzerinde gerçekleştirilen her türlü işleme konu olarak tarafımızdan işlenebilecektir. KVKK kapsamında veriler üzerinde gerçekleştirilen her türlü işlem “kişisel verilerin işlenmesi” olarak kabul edilmektedir.
    <br/><br/>
    <h2>Kişisel Verilerinizin İşlenme Amaçları Ve Hukuki Sebepleri:</h2>
    <br/><br/>
    Paylaştığınız kişisel veriler,
    <br/><br/>
    • Kullanıcılarımıza verdiğimiz hizmetlerin gereklerini, sözleşmenin ve teknolojinin gereklerine uygun şekilde yapabilmek, sunulan ürün ve hizmetlerimizi geliştirebilmek için;
    <br/><br/>
    • 6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun, 6502 sayılı Tüketicinin Korunması Hakkında Kanun ile bu düzenlemelere dayanak yapılarak hazırlanan 26.08.2015 tarihli 29457 sayılı RG’de yayımlanan Elektronik Ticarette Hizmet Sağlayıcı ve Aracı Hizmet Sağlayıcılar Hakkında Yönetmelik, 27.11.2014 tarihli ve 29188 sayılı RG’de yayımlanan Mesafeli Sözleşmeler Yönetmeliği ve diğer ilgili mevzuat kapsamında işlem sahibinin bilgilerini tespit için kimlik, adres ve diğer gerekli bilgileri kaydetmek için;
    <br/><br/>
    • Bankacılık ve Elektronik Ödeme alanında zorunlu olan ödeme sistemleri, elektronik sözleşme veya kağıt ortamında işleme dayanak olacak tüm kayıt ve belgeleri düzenlemek; mevzuat gereği ve diğer otoritelerce öngörülen bilgi saklama, raporlama, bilgilendirme yükümlülüklerine uymak için;
    <br/><br/>
    • Kamu güvenliğine ilişkin hususlarda ve hukuki uyuşmazlıklarda, talep halinde ve mevzuat gereği savcılıklara, mahkemelere ve ilgili kamu görevlilerine bilgi verebilmek için;
    <br/><br/>
    6698 sayılı KVKK ve ilgili ikincil düzenlemelere uygun olarak işlenecektir.
    <br/><br/>
    Kişisel verilerinizin aktarılabileceği üçüncü kişi veya kuruluşlar hakkında bilgilendirme yukarıda belirtilen amaçlarla, Firmamız ile paylaştığınız kişisel verilerinizin aktarılabileceği kişi/kuruluşlar; Firmamıza ödeme sistemi sunan iyzico Ödeme Hizmetleri AŞ, tedarikçiler, kargo şirketleri gibi sunulan hizmetler ile ilgili kişi ve kuruluşlar, faaliyetlerimizi yürütmek üzere ve/veya Veri İşleyen sıfatı ile hizmet alınan, işbirliği yaptığımız program ortağı kuruluşlar, yurtiçi/yurtdışı kuruluşlar ve diğer 3. kişilerdir.
    <br/><br/>
    <h2>Kişisel Verilerinizin Toplanma Şekli:</h2>
    <br/><br/>
    Kişisel verileriniz,
    <br/><br/>
    Firmamız İnternet sitesi (danteveistakoz.com) ve formlar aracılığıyla ad, soyadı, TC kimlik numarası, adres, telefon, iş veya özel e-posta adresi gibi bilgiler ile; hizmet alınan Vimeo, YouTube veya Spotify vb. tarafından toplanabilecek veriler, IP kayıtları, tarayıcı tarafından toplanan çerez verileri ile gezinme süre ve detaylarını içeren veriler, konum verileri şeklinde;
    <br/><br/>
    Firmamız ile ticari ilişki kurmak, iş başvurusu yapmak, teklif vermek gibi amaçlarla, kartvizit, özgeçmiş (cv), teklif vermek ve diğer yollarla kişisel verilerini paylaşan kişilerden alınan, fiziki veya sanal bir ortamda, yüz yüze ya da mesafeli, sözlü veya yazılı ya da elektronik ortamdan;
    <br/><br/>
    Ayrıca farklı kanallardan dolaylı yoldan elde edilen, İnternet sitesi, blog, yarışma, anket, oyun, kampanya ve benzeri amaçlı kullanılan (mikro) İnternet sitelerinden ve sosyal medyadan elde edilen veriler, e-bülten okuma veya tıklama hareketleri, kamuya açık veri tabanlarının sunduğu veriler, sosyal medya platformlarından paylaşıma açık profil ve verilerden; işlenebilmekte ve toplanabilmektedir.
    <br/><br/>
    <h2>Güvenlik Önlemleri ve Kredi Kartı Güvenliği:</h2>
    <br/><br/>
    Kredi kartı bilgileriniz hiçbir şekilde sistemimizde saklanmamaktadır.
    <br/><br/>
    Firmamız İnternet sitesinde (dantveistakoz.com) veri güvenliği sistemleri kullanılmaktadır. Tarayıcınızın en alt satırında bulunan anahtar ya da kilit simgesi güvenli bir İnternet sayfasında olduğunuzu gösterir ve her türlü bilgileriniz şifrelenerek korunur. Bu bilgiler, ancak satış işlemleri sürecine bağlı olarak ve verdiğiniz talimat istikametinde kullanılır. Alışveriş sırasında kullanılan kredi kartı ile ilgili bilgiler alışveriş sayfamızdan bağımsız olarak 128 bit SSL (Secure Sockets Layer) protokolü ile şifrelenip sorgulanmak üzere ilgili bankaya ulaştırılır. Kartın kullanılabilirliği onaylandığı takdirde alışverişe devam edilir. Kartla ilgili hiçbir bilgi tarafımızdan görüntülenemediğinden ve kaydedilmediğinden, üçüncü şahısların herhangi bir koşulda bu bilgileri ele geçirmesi engellenmiş olur. Ancak kullanıcı, tüm bunlara rağmen hiçbir sistem hakkında tam olarak güvence verilemeyeceğini unutmamalıdır.
    <br/><br/>
    Sitemizde başka İnternet sitelerine bağlantı verilebilir. Firmamız, bağlantı vasıtasıyla erişilen sitelerin gizlilik uygulamaları ve içeriklerine yönelik herhangi bir sorumluluk taşımamaktadır.
    <br/><br/>
    <h2>E-posta Güvenliği:</h2>
    <br/><br/>
    Tarafımıza herhangi bir siparişinizle ilgili olarak göndereceğiniz e-postalarda, asla kredi kartı numaranızı veya şifrelerinizi yazmayınız. E-postalarda yer alan bilgiler üçüncü şahıslar tarafından görülebilir. Firmamız e-postalarınızdan aktarılan bilgilerin güvenliğini hiçbir koşulda garanti edemez.
    <br/><br/>
    <h2>Kvkk Yürürlüğe Girmeden Önce Elde Edilen Kişisel Veriler:</h2>
    <br/><br/>
    KVKK’nın yürürlük tarihi olan 7 Nisan 2016 tarihinden önce, üyelik, elektronik ileti izni, ürün/hizmet satın alma ve diğer şekillerde hukuka uygun olarak elde edilmiş olan kişisel verileriniz de bu belgede düzenlenen şart ve koşullara uygun olarak işlenmekte ve muhafaza edilmektedir.
    <br/><br/>
    <h2>Kişisel Verilerin Yurtdışına Aktarılması:</h2>
    <br/><br/>
    Türkiye’de işlenerek veya Türkiye dışında işlenip muhafaza edilmek üzere, yukarıda sayılan yöntemlerden herhangi birisi ile toplanmış kişisel verileriniz KVKK kapsamında kalmak kaydıyla ve sözleşme amaçlarına uygun olarak yurtdışında bulunan (Kişisel Veriler Kurulu tarafından akredite edilen ve kişisel verilerin korunması hususunda yeterli korumanın bulunduğu ülkelere) hizmet aracılarına da aktarılabilecektir.
    <br/><br/>
    <h2>Kişisel Verilerin Saklanması Ve Korunması:</h2>
    <br/><br/>
    Kişisel verileriniz, Firmamız nezdinde yer alan veri tabanında ve sistemlerde KVKK’nın 12. maddesi gereğince gizli olarak saklanacak; yasal zorunluluklar ve bu belgede belirtilen düzenlemeler haricinde hiçbir şekilde üçüncü kişilerle paylaşılmayacaktır. Firmamız, kişisel verilerinizin barındığı sistemleri ve veri tabanlarını, KVKK’nın 12. maddesi gereği kişisel verilerin hukuka aykırı olarak işlenmesini önlemekle, yetkisiz kişilerin erişimlerini engellemekle, erişim yönetimi gibi yazılımsal tedbirleri ve fiziksel güvenlik önlemleri almakla mükelleftir. Kişisel verilerin yasal olmayan yollarla başkaları tarafından elde edilmesinin öğrenilmesi halinde durum derhal, yasal düzenlemeye uygun ve yazılı olarak Kişisel Verileri Koruma Kurulu’na bildirilecektir.
    <br/><br/>
    <h2>Kişisel Verilerin Güncel Ve Doğru Tutulması:</h2>
    <br/><br/>
    KVKK’nın 4. maddesi uyarınca Firmamızın kişisel verilerinizi doğru ve güncel olarak tutma yükümlülüğü bulunmaktadır. Bu kapsamda Firmamızın yürürlükteki mevzuattan doğan yükümlülüklerini yerine getirebilmesi için kullanıcılarımızın doğru ve güncel verilerini paylaşması gerekmektedir.
    <br/><br/>
    <h2>Değişiklik Hakları:</h2>
    <br/><br/>
    Firmamız, gelişen şartlar dahilinde herhangi bir zaman “Mesafeli Satış Sözleşmesi” ve “Gizlilik Politikası”nda değişiklik yapma hakkını saklı tutar. Buna hizmet bedeli eklenmesi vb. değişiklikler de dahildir. Bu eklemeler, değişiklikler, düzenlemeler, ya da iptaller ilan edilir edilmez İnternet sitesinde yayınlanacak, ayrıca gerekirse kullanıcıya e-posta yoluyla bildirilecektir. Değişiklikler, sitemizde yayınlandığı tarihte yürürlük kazanır.
    <br/><br/>
    <h2>6698 sayılı KVKK uyarınca kişisel veri sahibinin hakları:</h2>
    <br/><br/>
    6698 sayılı KVKK 11. maddesi 07 Ekim 2016 tarihinde yürürlüğe girmiş olup ilgili madde gereğince, Kişisel Veri Sahibi’nin bu tarihten sonraki hakları aşağıdaki gibidir:
    <br/><br/>
    Kişisel Veri Sahibi, Firmamıza (veri sorumlusu) başvurarak kendisiyle ilgili;
    <br/><br/>
    1- Kişisel veri işlenip işlenmediğini öğrenme,
    <br/><br/>
    2- Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
    <br/><br/>
    3- Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
    <br/><br/>
    4- Yurtiçinde veya yurtdışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,
    <br/><br/>
    5- Kişisel verilerin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme,
    <br/><br/>
    6- KVKK’nın 7. maddesinde öngörülen şartlar çerçevesinde kişisel verilerin silinmesini veya yok edilmesini isteme,
    <br/><br/>
    7- Kişisel verilerin düzeltilmesi, silinmesi, yok edilmesi halinde bu işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere de bildirilmesini isteme,
    <br/><br/>
    8- İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,
    <br/><br/>
    9- Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması halinde zararın giderilmesini talep etme, haklarına sahiptir.
    <br/><br/>
    Ankara Ticaret Odası’na 222960 Ticaret Sicil No’syla ve 0211068446700001 Mersis No’suyla kayıtlı Cogita Bilişim Tanıtım Mühendislik Taahhüt Danışmanlık Turizm ve Ticaret Sanayi Anonim Şirketi, Üniversiteler Mahallesi 1597. Cadde Kümeevler No:62 D:2 Bilkent Çankaya Ankara adresinde yer alır ve KVKK kapsamında 
    Veri Sorumlusu’dur.
    <br/><br/>
    Firmamız tarafından atanacak Veri Sorumlusu Temsilcisi, yasal altyapı sağlandığında Veri Sorumluları Sicilinde ve bu belgenin bulunduğu İnternet adresinde ilan edilecektir. Kişisel Veri Sahipleri, sorularını, görüşlerini veya taleplerini aşağıdaki e-posta adresine yöneltebilir:
    <br/><br/>
    <h2>E-posta:</h2> danteveistakoz@gmail.com`,
  },
  {
    mainTitle: 'TESLİMAT VE İADE POLİTİKASI',
    image: Teslimat,
    link: '/iade-politikasi',
    textData: `<h2>TESLİMATA DAİR</h2>
    <br/><br/>
    Siparişiniz kargoya en geç 7 iş günü içerisinde teslim edilir. 
    Dante ve Istakoz aksi belirtilmedikçe UPS Kargo’ya siparişinizi teslim eder, alıcıya teslimat süresi adresinize göre değişim gösterebilir. 
    <br/><br/>
    <h2>İADEYE DAİR</h2>
    <br/><br/>
    Şimdiye kadar kimse bir yayını veya nesneyi iade etmeyi talep etmedi. Dolayısıyla cayma nedeninizi belirten e-postaya faturanızı da eke koyarak talebinizi ulaştırarak iade sürecini başlatabilirsiniz. Bunun için siparişinizi teslim aldığınız tarihten itibaren 14 günü geçmemiş, dış kapağı ve iç sayfaları hiç zarar görmemiş [yeniden satılabilirlik özelliği bozulmamış] yayınları, o anki kullanım durumunu fotoğraf ve/veya videoyla belgeleyerek, eksiksiz ve faturası ile birlikte danteveistakoz@gmail.com’a illettiğiniz koşulda size iade talebinizle ilgili dönülür. İade talebiniz onaylandığı takdirde Mesafeli Satış Sözleşmesi gereği iadesi gerçekleşen ürünün ödemesi, ileteceğimiz alışveriş yapmış olduğunuz kredi kartınıza 14 iş günü içerisinde gerçekleştirilecektir. Para iadeniz, iade talebiniz onaylandıktan sonra 14 iş günü içerisinde kartınıza iade edilir, bu süreçte bankanızdan bilgi almanız öneriliyor.`,
  },
];

const TextPage = () => {
  const location = useLocation();
  const currentLink = location.pathname;
  const currentData = data.filter(i => i.link === currentLink)[0];

  console.log(currentLink);
  return (
    <>
      <div className={style.textPage}>
        <Header shop />
        <div className={style.content}>
          <img
            src={currentData.image}
            alt={currentData.mainTitle}
            className={style.mainImage}
          />
          <div className={style.textWrapper}>
            <h1>{currentData.mainTitle}</h1>

            <div
              className={style.text}
              dangerouslySetInnerHTML={{ __html: currentData.textData }}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TextPage;
