import style from './callback.module.scss';
import { Header, Footer } from '../../components';
import SuccessIllustration from '../../assets/img/callback/success.svg';
import ErrorIllustration from '../../assets/img/callback/error.svg';
import { useLocation } from 'react-router-dom';
import CartSeperator from '../../assets/img/shop/cart-seperator-icon.svg';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { clearCart } from '../../redux/actions';

const CallBack = () => {
  const location = useLocation();
  const currentPath = location.pathname.split('/')[2];
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentPath === 'success') {
      dispatch(clearCart());
    }
  }, []);

  return (
    <>
      <div className={style.callback}>
        <Header />
        <div className={`${style.content} ${style[currentPath]}`}>
          {currentPath === 'error' ? (
            <div className={style.wrapper}>
              <img
                className={style.mainImage}
                src={ErrorIllustration}
                alt="error"
              />
              <h1>MAALESEF YOLUNDA GİTMEYEN BİR ŞEYLER OLDU.</h1>
              <img
                className={style.seperator}
                src={CartSeperator}
                alt="seperator"
              />
              <a href="/cart">ÖNCEKİ SAYFAYA GİDELİM BENCE?</a>
            </div>
          ) : (
            <div className={style.wrapper}>
              <h1>
                SİPARİŞİNİZ BAŞARIYLA TAMAMLANDI. <br />
                HAZIRLANIP SİZE UÇURULACAK.
              </h1>
              <img src={SuccessIllustration} alt="success" />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CallBack;
