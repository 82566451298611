import style from './home.module.scss';

import Logo from '../../assets/img/logo/dante-logo.png';
import BookBottom from '../../assets/img/home/book-bottom.png';
import Castle from '../../assets/img/home/castle.png';
import Istakoz from '../../assets/img/home/istakoz.png';
import Logotype from '../../assets/img/logo/logotype.svg';
import MobileSeperator from '../../assets/img/home/mobile-seperator.svg';
import { useEffect, useState } from 'react';
import { Footer } from '../../components';
import useWindowDimensions from '../../helpers/windowWidth';
import { LanguageData } from '../../utils/LanguageData';
import { useSelector, useDispatch } from 'react-redux';
import { changeLanguage } from '../../redux/actions';

const Home = () => {
  const [hide, setHide] = useState(false);
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const currentLang = useSelector(state => state.settings.language);

  useEffect(() => {
    setTimeout(() => {
      setHide(true);
    }, 10000);
  }, []);

  return (
    <>
      <div className={style.home}>
        {width > 938 && (
          <img src={Istakoz} alt="istakoz" className={style.istakoz} />
        )}
        {width < 938 ? (
          <div className={style.mobile}>
            <img className={style.logo} src={Logotype} alt="logo" />
            <img src={Istakoz} alt="istakoz" className={style.istakoz} />
            <img
              src={MobileSeperator}
              alt="istakoz"
              className={style.seperator}
            />
            <img src={BookBottom} alt="istakoz" className={style.book} />
            <a href="/shop" className={`${style.shop} `}>
              {LanguageData[currentLang].header.shop[1]}
              <br /> {LanguageData[currentLang].header.shop[2]}
              <div className={style.circle} />
              <div className={style.circle} />
              <div className={style.circle} />
              <div className={style.circle} />
            </a>
            <img
              src={MobileSeperator}
              alt="istakoz"
              className={style.seperator}
            />
            <div className={style.languageWrapper}>
              <img src={Castle} alt="istakoz" className={style.castle} />
              <div
                onClick={() => {
                  dispatch(changeLanguage(currentLang === 'en' ? 'tr' : 'en'));
                }}
                className={style.languageButton}
              >
                <h6>{currentLang === 'en' ? 'TÜRKÇE' : 'ENGLISH'}</h6>
              </div>
            </div>
            <a href="/about" className={`${style.about} `}>
              {LanguageData[currentLang].header.about[1]}
              <br /> {LanguageData[currentLang].header.about[2]}
              <div className={style.circle} />
              <div className={style.circle} />
              <div className={style.circle} />
              <div className={style.circle} />
              <div className={style.circle} />
            </a>
          </div>
        ) : (
          <div className={style.left}>
            <div className={style.top}>
              <img
                className={style.logo}
                src={width < 938 ? Logotype : Logo}
                alt="logo"
              />
              <div className={style.buttonWrapper}>
                <a href="/shop" className={`${style.shop} `}>
                  {LanguageData[currentLang].header.shop[1]}
                  <br /> {LanguageData[currentLang].header.shop[2]}
                  <div className={style.circle} />
                  <div className={style.circle} />
                  <div className={style.circle} />
                  <div className={style.circle} />
                </a>
                <a href="/about" className={`${style.about} `}>
                  {LanguageData[currentLang].header.about[1]}
                  <br /> {LanguageData[currentLang].header.about[2]}
                  <div className={style.circle} />
                  <div className={style.circle} />
                  <div className={style.circle} />
                  <div className={style.circle} />
                  <div className={style.circle} />
                </a>
              </div>
              <div
                onClick={() => {
                  dispatch(changeLanguage(currentLang === 'en' ? 'tr' : 'en'));
                }}
                className={style.languageButton}
              >
                <h6>{currentLang === 'en' ? 'TÜRKÇE' : 'ENGLISH'}</h6>
              </div>
            </div>
            <div className={style.bottom}>
              <img className={style.book} src={BookBottom} alt="book" />
              <p
                style={{
                  opacity: hide ? 0 : 1,
                }}
              >
                CURRENTLY WORKING ON INTERNATIONAL
                <br />
                SHIPPING OPTIONS. PLEASE STAY TUNED
                <br />
                FOR THAT.
              </p>
              <img className={style.castle} src={Castle} alt="castle" />
            </div>
          </div>
        )}
        {/* <img src={Castle} alt="castle" />
      <img src={Istakoz} alt="istakoz" /> */}
      </div>
      <Footer />
    </>
  );
};

export default Home;
