import style from './footer.module.scss';
import Arrow from '../../assets/img/footer/arrow.svg';
import ArrowMobile from '../../assets/img/footer/arrow-mobile.svg';
import ArrowSeperator from '../../assets/img/footer/arrow-seperator.svg';
import iyzico from '../../assets/img/footer/iyzico.png';
import { LanguageData } from '../../utils/LanguageData';
import { useSelector } from 'react-redux';
import MobileLogo from '../../assets/img/footer/mobile-logo.png';
import useWindowDimensions from '../../helpers/windowWidth';

import BottomSeperator from '../../assets/img/footer/bottom-seperator.svg';

const Footer = () => {
  const currentLang = useSelector(state => state.settings.language);
  const { width } = useWindowDimensions();
  return (
    <footer className={style.footer}>
      <div className={style.wrapper}>
        <div className={style.leftArrow}>
          <img src={width < 938 ? MobileLogo : Arrow} alt="arrow" />
        </div>
        <div className={style.center}>
          <div className={style.info}>
            <h2>
              Dante ve Istakoz
              <br />
              <span>© 2023</span>
            </h2>
            <p>
              {LanguageData[currentLang].footer.aboutText}
              {/* <br />
              <br /> */}
              {/* Cogita A.Ş. */}
            </p>
          </div>
          <img
            className={style.seperator}
            src={width < 938 ? ArrowMobile : ArrowSeperator}
            alt="seperator"
          />
          <div className={`${style.info} ${style.centerInfo}`}>
            <a href="/about">{`> ${LanguageData[currentLang].footer.about}`}</a>
            <a>{`> ${LanguageData[currentLang].footer.contact}`}</a>
            <h3
              onClick={() => {
                window.open('mailto:danteveistakoz@gmail.com', '__blank');
              }}
            >
              danteveistakoz@gmail.com
            </h3>
            <a>{`> ${LanguageData[currentLang].footer.socialMedia}`}</a>
            <h3
              onClick={() => {
                window.open(
                  'https://www.instagram.com/danteveistakoz',
                  '__blank'
                );
              }}
            >
              instagram.com/danteveistakoz
            </h3>
          </div>
          <img
            className={style.seperator}
            src={width < 938 ? ArrowMobile : ArrowSeperator}
            alt="seperator"
          />

          <div className={`${style.info} ${style.rightInfo}`}>
            <h2>{`${LanguageData[currentLang].footer.importantLinks}`}</h2>
            <a href="/gizlilik-politikasi">{`> ${LanguageData[currentLang].footer.privacyPolicy}`}</a>
            <a href="/mesafeli-satis-sozlesmesi">
              {`> ${LanguageData[currentLang].footer.distance}`}
            </a>
            <a href="/iade-politikasi">{`> ${LanguageData[currentLang].footer.return}`}</a>
            <h5
              style={{
                opacity: currentLang === 'en' ? 0 : 1,
              }}
            >
              Yayınlar İlgili Sayfalarından İncelenebilir,
              <br /> Sipariş Verilebilir
            </h5>
            <img src={iyzico} alt="iyzico" className={style.iyzico} />
          </div>
        </div>
        <div className={style.rightArrow}>
          <img src={width < 938 ? BottomSeperator : Arrow} alt="arrow" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
